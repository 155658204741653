import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import includes from 'lodash/includes';
import some from 'lodash/some';
import { useTheme } from 'styled-components';

import { Routes } from '@features/core/router';
import { HeaderLogo } from '@features/core/theming/contexTheme';
import { AppLayout } from '@features/app/layout/desktop';
import { Header } from '@features/app/layout/header';
import { FooterWrapper } from '@features/app/layout/footer';
import services from '@features/core/services';
import GlobalStyles from '@features/core/GlobalStyles';
import { getPageURL, PageName } from '@features/core/routing/linkAliases';
import { UserInfo } from '@features/app/layout/userInfo';
import { InboxMessageCount } from '@features/inbox/inboxMessageCount';
import LegalAgeIcon from '@features/user/components/legalAgeIcon/components/LegalAgeIcon';

import {
  DEFAULT_LANGUAGE,
  HAS_MY_BETS,
  HAS_NEWS,
  HAS_PROMOTIONS,
  HAS_REGISTRATION,
  HAS_STATIC_PAGES,
} from '@common/constants/config';
import { IS_LOGGED_IN } from '@common/constants/cookie';
import { ButtonSize, ButtonType } from '@common/interfaces';
import { DEFAULT_SELECTED_CATEGORY_TYPE_TAB } from '@common/providers/events/liveCategories/state';
import { isAccountPages } from '@common/helpers/links';
import {
  getDefaultRouteName,
  isMatchWebRoutes,
  setLocation,
} from '@common/providers/router/helper';
import { useUserState } from '@common/providers/user/useUserState';
import {
  setSelectedCategoryTypeTab,
  toggleLiveCategory,
} from '@common/providers/events/liveCategories/useLiveCategories';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { getWallets } from '@common/providers/payments/wallets/helpers';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';
import { isIframeView } from '@common/helpers/appLayoutHelper';
import useFormSubmitWithKeyboard from '@common/hooks/useKeyPress';
import { UserLoadingState } from '@common/providers/user/actions/actionTypes';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './DesktopLayout.styled';
import { BetsButtonWrapper } from './DesktopLayout.styled';

const DesktopLayout: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const loadingState = useUserState(s => s.loadingState);
  const isLoggedIn =
    loadingState >= UserLoadingState.PROCESSING ||
    !!services.cookie?.get(IS_LOGGED_IN);
  const user = useUserState(state => state.data);
  const wallet = useWalletsState(state => state.data);
  const error = useWalletsState(state => state.error);
  const timerRef = useRef<HTMLDivElement>(null);
  const currentDate = new Date();
  const { pathname } = useLocation();
  const hasRegistration = services.config.get(HAS_REGISTRATION);
  const isAccount = isAccountPages(pathname);
  const theme: { main: string } = useTheme();

  const isSingleWallet =
    (wallet.length === 2 && some(wallet, ['type', IUserWalletType.bonus])) ||
    wallet.length < 2;

  useFormSubmitWithKeyboard();

  useEffect(() => {
    if (timerRef.current) {
      timerRef.current.innerHTML = currentDate.toLocaleTimeString();
    }

    setInterval(() => {
      currentDate.setSeconds(currentDate.getSeconds() + 1);

      if (timerRef.current) {
        timerRef.current.innerHTML = currentDate.toLocaleTimeString();
      }
    }, 1000);
  }, []);

  const handleClickOnProfileIcon = (): void => {
    getWallets();
    setLocation(PageName.USER_PROFILE);
  };

  const isActive = (item): boolean => {
    if (item !== PageName.HOME) {
      return includes(pathname, getPageURL(item));
    }
    return (
      getPageURL(item) === pathname ||
      isMatchWebRoutes(PageName.EVENTS_CATEGORY) ||
      isMatchWebRoutes(PageName.SPORT)
    );
  };

  const renderNavMenu = (): JSX.Element => {
    const menuItems = {
      sportwetten: PageName.HOME,
      live: PageName.LIVE,
      ...(services.config.get(HAS_NEWS) && {
        news:
          services.config.get(DEFAULT_LANGUAGE) === services.domainLang
            ? PageName.NEWS
            : null,
      }),
      ...(services.config.get(HAS_PROMOTIONS) &&
        services.config.get(HAS_STATIC_PAGES) && {
          aktionen: PageName.PROMOTIONS,
        }),
    };

    return (
      <S.NavMenu>
        {map(menuItems, (item: PageName, key: string) => {
          if (item === null) {
            return;
          }
          // eslint-disable-next-line consistent-return
          return (
            <S.MenuItem
              key={`${item}_${key}`}
              onClick={(): void => {
                if (item === PageName.LIVE) {
                  toggleLiveCategory({ category: '' });
                }
                setLocation(item, {}, { forceReload: item === PageName.LIVE });
              }}
              isActive={isActive(item)}
              className={isActive(item) ? 'active' : ''}
              data-qa={`nav-item-${item}`}
            >
              {t(`home_page.navigation_keys.${key}`)}
            </S.MenuItem>
          );
        })}
      </S.NavMenu>
    );
  };

  const leftContent = (
    <S.DesktopLogoContainer>
      {!isIframeView() && (
        <>
          <S.LogoContainer
            href={getPageURL(getDefaultRouteName())}
            onClick={(e): void => {
              e.preventDefault();
              setSelectedCategoryTypeTab({
                selectedCategoryTypeTab: DEFAULT_SELECTED_CATEGORY_TYPE_TAB,
              });
              setLocation(PageName.HOME);
            }}
            ariaLabel={t('common.links.logo')}
          >
            <HeaderLogo />
          </S.LogoContainer>

          <LegalAgeIcon />
        </>
      )}
      {renderNavMenu()}
    </S.DesktopLogoContainer>
  );

  const rightContent = (
    <S.RightContentContainer>
      <div id="test-chat" />

      {isLoggedIn ? (
        <S.RightContentLoggedInContainer>
          <S.LoginInfo>
            <S.UserContainer>
              {!isIframeView() && (
                <>
                  {!error && user && (
                    <UserInfo user={user} isSingleWallet={isSingleWallet} />
                  )}
                  <StyledButton
                    onClick={(): void => setLocation(PageName.USER_PAYIN)}
                    size={ButtonSize.Small}
                    buttonType={ButtonType.Primary}
                    label="payments.deposit"
                    className="user-btn"
                  />
                </>
              )}
              {services.config.get(HAS_MY_BETS) && (
                <BetsButtonWrapper>
                  <StyledButton
                    onClick={(): void => setLocation(PageName.USER_BETS)}
                    size={ButtonSize.Small}
                    buttonType={ButtonType.LightGhost}
                    label="app-layout.user-menu.gained_bets"
                    className="user-btn"
                  />
                </BetsButtonWrapper>
              )}
            </S.UserContainer>

            {!isIframeView() && (
              <S.UserLetter
                onClick={handleClickOnProfileIcon}
                data-qa="header-user-letter"
              >
                <Icon name="user" width="30" height="30" fill={theme.main} />

                {isAccount && <S.Triangle />}
              </S.UserLetter>
            )}
            <InboxMessageCount pageType="desktop-profile-icon" />
          </S.LoginInfo>

          <S.ClockContainer>
            <S.ClockTimeIcon>
              <Icon
                name="light-clock"
                width="16"
                height="16"
                viewBox="0 0 1024 1024"
              />
            </S.ClockTimeIcon>

            <S.ClockTimeLabel ref={timerRef} />
          </S.ClockContainer>
        </S.RightContentLoggedInContainer>
      ) : (
        <S.LoginContainer>
          {hasRegistration && (
            <StyledButton
              onClick={(e): void => {
                e.preventDefault();
                setLocation(PageName.REGISTER);
              }}
              buttonType={ButtonType.Secondary}
              className="register-btn"
              label="home_page.register"
            />
          )}
          {!isIframeView() && (
            <S.LoginButtonsContainer
              onClick={(): void => setLocation(PageName.LOGIN)}
            >
              <S.LoginBtn id="login-btn" data-qa="header-btn-login">
                <Icon
                  name="login"
                  width="20"
                  height="20"
                  viewBox="0 0 486 486"
                />
              </S.LoginBtn>

              <S.LoginLabel>{t('user.loginPage.loginBtn')}</S.LoginLabel>
            </S.LoginButtonsContainer>
          )}
        </S.LoginContainer>
      )}
    </S.RightContentContainer>
  );

  const centerContent = isLoggedIn ? null : (
    <S.ClockContainer>
      <S.ClockTimeIcon>
        <Icon
          name="light-clock"
          width="16"
          height="16"
          viewBox="0 0 1024 1024"
        />
      </S.ClockTimeIcon>

      <S.ClockTimeLabel ref={timerRef} />
    </S.ClockContainer>
  );

  const header = (
    <Header
      loggedIn={!!isLoggedIn}
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    />
  );

  const center = <Routes />;

  const footer = <FooterWrapper />;

  return (
    <GlobalStyles hasFixedProfileMenu={isAccount}>
      <AppLayout header={header} center={center} footer={footer} />
    </GlobalStyles>
  );
};

export default DesktopLayout;
