import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const Text = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
`;

export const CashoutTitleWrapper = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-areas: 'item1 . . item2';
  justify-content: space-between;
  margin-bottom: ${props => props.theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
  align-items: center;

  & > span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: ${({ open }) => (open ? '' : 'item1')};
  }

  & > span:last-child {
    grid-area: ${({ open }) => (open ? '' : 'item2')};
  }

  & svg {
    margin-right: ${({ theme }) => theme.space_10};
  }
`;

export const CashoutTitle = styled(TextNormal)<{ isBonus?: boolean }>`
  display: flex;
  ${({ isBonus }) => (isBonus ? 'height: 17px' : '')};
  font-size: ${props => props.theme.fontSM};
  align-items: center;

  & span {
    ${({ isBonus }) => (isBonus ? 'align-self: center;' : '')};
  }
`;

export const LiveEventNamesWrapper = styled.div`
  display: flex;
`;

export const CashoutLive = styled.div`
  color: ${({ theme }) => theme.accent};
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  font-size: ${({ theme }) => theme.fontXS};
  margin-right: ${({ theme }) => theme.space_5};

  svg > path {
    fill: ${({ theme }) => theme.accent};
  }
`;

export const EventsNamesWrapper = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.fontSM};
  max-width: 255px;
`;

export const GewinnChanceWrapper = styled.span`
  text-align: right;
  white-space: nowrap;
  ${({ theme }) => theme.isDesktop && 'margin-right:4px;'}
`;
