import React, { useCallback } from 'react';

import * as S from '@features/detail/components/detailEventHeader/DetailEventHeader.styled';
import {
  FootballCards,
  TeamLabel,
} from '@features/events/components/scoreboards/scores';
import {
  FactoryScoreboards,
  FactoryTimers,
  ScoreboardTypes,
} from '@features/events/components/scoreboards';

import { isPeriodNotStarted } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { isWinner } from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  getAwayLabel,
  getHomeLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import { EventListTypes, IEvent } from '@common/interfaces';
import { ICategory } from '@common/interfaces/events/ICategories';
import {
  isFootball,
  isHandball,
} from '@common/helpers/eventsHelper/eventTypeHelper';

export interface IDetailEventHeaderProps {
  event: IEvent;
  category: ICategory;
  listType: keyof typeof EventListTypes;
}
const DetailEventHeader = (props: IDetailEventHeaderProps): JSX.Element => {
  const { event, category, listType } = props;
  const hasGenericScore = isFootball(event) || isHandball(event);
  const genericScoreHeader = useCallback(
    (): React.ReactElement => (
      <>
        <S.LeftSection>
          <S.Title data-qa="team-home-label">
            {getHomeLabel(event.label || '')}
          </S.Title>
          <FootballCards
            side="left"
            yellow={event.cards?.yellow?.[0]}
            red={event.cards?.red?.[0]}
            isDetailEvent
          />
        </S.LeftSection>

        <S.CenterSection className="score">
          <FactoryTimers
            id={event.id}
            topCategoryId={category.top_category_id}
            listType={listType}
            shortTimer={false}
          />
          <FactoryScoreboards
            id={event.id}
            event={event}
            topCategoryId={category.top_category_id}
            type={ScoreboardTypes.large}
            listType={listType}
          />
        </S.CenterSection>

        <S.RightSection className="timer">
          <S.RightSectionCell>
            <S.Title data-qa="team-away-label">
              {getAwayLabel(event.label || '')}
            </S.Title>
            <FootballCards
              side="right"
              yellow={event.cards?.yellow?.[1]}
              red={event.cards?.red?.[1]}
              isDetailEvent
            />
          </S.RightSectionCell>
        </S.RightSection>
      </>
    ),
    [event, category, listType],
  );

  if (isPeriodNotStarted(event.timer)) {
    return (
      <S.EventHeader>
        <TeamLabel event={event} category={category} listType={listType} />
        <FactoryTimers
          id={event.id}
          topCategoryId={category.top_category_id}
          listType={listType}
          shortTimer={false}
        />
      </S.EventHeader>
    );
  }
  return (
    <>
      {!isWinner(event) && (
        <S.EventHeader hasGenericScore={hasGenericScore}>
          {hasGenericScore ? (
            genericScoreHeader()
          ) : (
            <>
              <TeamLabel
                event={event}
                category={category}
                listType={listType}
              />
              <S.ScoreboardWrapper>
                <FactoryTimers
                  id={event.id}
                  topCategoryId={category.top_category_id}
                  listType={listType}
                  shortTimer={false}
                />
                <FactoryScoreboards
                  id={event.id}
                  event={event}
                  topCategoryId={category.top_category_id}
                  type={ScoreboardTypes.large}
                  listType={listType}
                />
              </S.ScoreboardWrapper>
            </>
          )}
        </S.EventHeader>
      )}

      {isWinner(event) && (
        <S.EventHeader>
          <S.LeftSection>
            <S.Title>{getHomeLabel(event.label || '')}</S.Title>
          </S.LeftSection>

          <S.RightSection>
            <S.RightSectionCell>
              <FactoryTimers
                id={event.id}
                topCategoryId={category.top_category_id}
                listType={listType}
                shortTimer={false}
              />
            </S.RightSectionCell>
          </S.RightSection>
        </S.EventHeader>
      )}
    </>
  );
};
export default DetailEventHeader;
