import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  flex-shrink: 0;
  height: 25px;
  width: 25px;
  margin-right: ${({ theme }) => theme.space_10};
`;

export const ImageStyled = styled.img`
  display: block;
  max-width: 100%;
  align-self: center;
  margin-right: ${({ theme }) => theme.space_5};
`;
