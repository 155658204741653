export const HOME = {
  en: '/en',
  de: '/',
};
export const INFORMATION = {
  en: '/en/information/:id',
  de: '/informationen/:id',
};
export const COMPANY = {
  en: '/en/company/:id',
  de: '/unternehmen/:id',
};
export const RESPONSIBILITY = {
  en: '/en/responsibility/:id',
  de: '/verantwortung/:id',
};
export const SUPPORT = {
  en: '/en/company/contact',
  de: '/unternehmen/kontakt',
};
export const LIVE = {
  en: '/en/livebets/:offset?',
  de: '/livewetten/:offset?',
};
export const BETTING_SLIP = {
  en: '/en/betting-slip',
  de: '/betting-slip',
};
export const PROMOTIONS = {
  en: '/en/promotions',
  de: '/aktionen',
};
export const PROMOTIONS_PAGES = {
  en: '/en/promotions/:id',
  de: '/aktionen/:id',
};
export const CASINO = {
  en: '/en/slots',
  de: '/slots',
};
export const FORGOT_PASSWORD = {
  en: '/en/forgot-password',
  de: '/forgot-password',
};
export const REGISTRATION_BONUS = {
  en: '/en/bonus',
  de: '/bonus',
};
export const REGISTER = {
  en: '/en/register(-\\d+)?',
  de: '/register(-\\d+)?',
};
export const FULL_REGISTER = {
  en: '/en/fullregister',
  de: '/fullregister',
};
export const LOGIN = {
  en: '/en/login',
  de: '/login',
};
export const USER_OVERVIEW = {
  en: '/en/user/overview',
  de: '/user/overview',
};
export const TICKET_SCANNER = {
  en: '/en/scanbet',
  de: '/scanbet',
};
export const SCANNER_RESULT = {
  en: '/en/scanbet/:id',
  de: '/scanbet/:id',
};
export const CASHOUT_SCANNER_RESULT = {
  en: '/en/scanbetcashout/:id',
  de: '/scanbetcashout/:id',
};
export const LITTLE_CASHOUT = {
  en: '/en/little-cashout/:id',
  de: '/little-cashout/:id',
};
export const MANUAL_BARCODE_ENTRY = {
  en: '/en/scanbet-manual',
  de: '/scanbet-manual',
};
export const USER_BETS = {
  en: '/en/user/bets',
  de: '/user/bets',
};
export const USER_BET_ITEM = {
  en: '/en/user/bets/:id',
  de: '/user/bets/:id',
};
export const USER_PAYOUT = {
  en: '/en/user/payout',
  de: '/user/payout',
};
export const USER_PAYIN = {
  en: '/en/user/payin',
  de: '/user/payin',
};
export const USER_CASHOUT = {
  en: '/en/user/cashout',
  de: '/user/cashout',
};
export const USER_LIMITS = {
  en: '/en/user/limits',
  de: '/user/limits',
};
export const USER_LIMITS_HISTORY = {
  en: '/en/user/limits-history',
  de: '/user/limits-history',
};
export const USER_LIMITS_LOCK = {
  en: '/en/user/limits-lock',
  de: '/user/limits-lock',
};
export const USER_NOTIFICATIONS = {
  en: '/en/user/notifications',
  de: '/user/notifications',
};
export const USER_PROFILE = {
  en: '/en/user/profile',
  de: '/user/profile',
};
export const USER_CHANGE_PASSWORD = {
  en: '/en/user/profile/change-password',
  de: '/user/profile/change-password',
};
export const SET_LIMITS = {
  en: '/en/user/set-limits',
  de: '/user/set-limits',
};
export const USER_VERIFICATION = {
  en: '/en/user/verification',
  de: '/user/verification',
};
export const USER_VERIFICATION_YEARLY = {
  en: '/en/user/verification-yearly',
  de: '/user/verification-yearly',
};
export const USER_DELETE_ACCOUNT = {
  en: '/en/user/delete-account',
  de: '/user/delete-account',
};
export const USER_CASHOUT_ITEM = {
  en: '/en/user/cashout/:betId',
  de: '/user/cashout/:betId',
};
export const USER_BONUSES = {
  en: '/en/user/bonuses',
  de: '/user/bonuses',
};
export const USER_TAB = {
  en: '/en/user/:tab',
  de: '/user/:tab',
};
export const USER_PAYIN_INFO_BANK = {
  en: '/en/user/payin/info/bank',
  de: '/user/payin/info/bank',
};
export const USER_PAYIN_INFO_METHOD = {
  en: '/en/user/payin/info/:method',
  de: '/user/payin/info/:method',
};
export const USER_PAYIN_CARD_AMOUNT = {
  en: '/en/user/payin/card/:amount',
  de: '/user/payin/card/:amount',
};
export const USER_PAYOUT_BANK_AMOUNT = {
  en: '/en/user/payout/bank/:amount',
  de: '/user/payout/bank/:amount',
};
export const USER_PAYIN_INFO_SKRILL = {
  en: '/en/user/payin/info/skrill',
  de: '/user/payin/info/skrill',
};
export const USER_PAYIN_QR = {
  en: '/en/user/payin/qr/:amount',
  de: '/user/payin/qr/:amount',
};
export const USER_PAYIN_SUCCESS = {
  en: '/en/user/payin/success',
  de: '/user/payin/success',
};
export const USER_PAYOUT_SUCCESS = {
  en: '/en/user/payout/success',
  de: '/user/payout/success',
};
export const USER_PAYIN_FAILURE = {
  en: '/en/user/payin/fail',
  de: '/user/payin/fail',
};
export const USER_PAYOUT_FAILURE = {
  en: '/en/user/payout/fail',
  de: '/user/payout/fail',
};
export const USER_PAYIN_PREPARED = {
  en: '/en/user/payin/prepared',
  de: '/user/payin/prepared',
};
export const USER_PAYOUT_PREPARED = {
  en: '/en/user/payout/prepared',
  de: '/user/payout/prepared',
};
export const USER_GDPR = {
  en: '/en/user/gdpr',
  de: '/user/gdpr',
};
export const USER_UPLOADS = {
  en: '/en/user/uploads',
  de: '/user/uploads',
};
export const EVENTS_ITEM = {
  en: '/en/events/:eventId',
  de: '/events/:eventId',
};
export const EVENTS_ITEM_LMT = {
  en: '/en/events/:tab/:betradarId',
  de: '/events/:tab/:betradarId',
};
export const EVENTS_CATEGORY = {
  en:
    '/en/events/category/:categoryId([-0-9]+|all|favorites)/:filter(all|today|next24hrs|next3hrs)?/:offset?',
  de:
    '/events/category/:categoryId([-0-9]+|all|favorites)/:filter(all|today|next24hrs|next3hrs)?/:offset?',
};

export const NEWS = {
  en: '/en/news',
  de: '/news',
};

export const EM_NEWS = {
  en: '/en/em2024',
  de: '/em2024',
};
export const SPONSORING = {
  en: '/en/company/sponsoring',
  de: '/unternehmen/sponsoring',
};
export const FAQ_LUGAS = {
  en: '/en/faq-lugas',
  de: '/faq-lugas',
};
export const REGISTRATION_DISABLED = {
  en: '/en/registration/disabled',
  de: '/registration/disabled',
};
export const NEWS_ITEM_CATEGORY = {
  en: '/en/news/:newsCategory?',
  de: '/news/:newsCategory?',
};
export const NEWS_CATEGORY_DETAIL = {
  en: '/en/news/:newsCategory/:detailTitle',
  de: '/news/:newsCategory/:detailTitle',
};
export const NEWS_CATEGORY = {
  en: '/en/news/:category',
  de: '/news/:category',
};
export const UNTERNEHMEN_PRESSE_DETAIL = {
  en: '/en/unternehmen/presse/:detailTitle',
  de: '/unternehmen/presse/:detailTitle',
};
export const NEWS_ITEM = {
  en: '/en/news/:category/:item',
  de: '/news/:category/:item',
};
export const UNTERNEHMEN_PRESSE = {
  en: '/en/unternehmen/presse',
  de: '/unternehmen/presse',
};
export const PRESSE = {
  en: '/en/presse',
  de: '/presse',
};
export const MIGRATION = {
  en: '/en/migration',
  de: '/migration',
};
export const SPORT = {
  en: '/en/sport',
  de: '/sport',
};
export const CREATE_ONLINE_ACCOUNT = {
  en: '/en/create-online-account',
  de: '/create-online-account',
};
export const UPDATE_ONLINE_ACCOUNT = {
  en: '/en/update-online-account',
  de: '/update-online-account',
};
export const YEARLY_DATA_CHECK = {
  en: '/en/yearly-data-check',
  de: '/yearly-data-check',
};
export const USER_INBOX = {
  en: '/en/user/inbox',
  de: '/user/inbox',
};
export const USER_INBOX_MESSAGE = {
  en: '/en/user/inbox/:id([-0-9]+)',
  de: '/user/inbox/:id([-0-9]+)',
};
export const PAYMENT_METHODS = {
  en: '/en/payment/methods',
  de: '/payment/methods',
};

export const REGISTRATION_SUCCESS = {
  en: '/en/register-success',
  de: '/register-success',
};

export const USER_EMAIL_VERIFICATION_SUCCESS = {
  en: '/en/verify-email-confirmation',
  de: '/verify-email-confirmation',
};

export const USER_EMAIL_VERIFICATION_FAILURE = {
  en: '/en/verify-email-failure',
  de: '/verify-email-failure',
};

export const USER_EMAIL_VERIFICATION_ONLINE = {
  en: '/en/verify-email-online',
  de: '/verify-email-online',
};

export const USER_EMAIL_VERIFICATION_ONLINE_SUCCESS = {
  en: '/en/verify-email-online-confirmation',
  de: '/verify-email-online-confirmation',
};

export const TEST_PAGE = {
  en: '/en/test-page',
  de: '/test-page',
};
export const ODDS_COMPARISON = {
  en: '/en/odds-comparison',
  de: '/odds-comparison',
};

export const MY_FAVORITO = {
  en: '/en/user/my-favorito',
  de: '/user/my-favorito',
};

export const ODDS_BOOST = {
  en: '/en/oddsboost/:id',
  de: '/quotenboost/:id',
};
