import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

export const Root = styled.div`
  background-color: ${({ theme }) => theme.bodyBackground};
  overflow: scroll;
  display: grid;
  grid-auto-flow: ${({ theme }) => (!theme.isDesktop ? 'row' : 'column')};
  ${({ theme }) => (theme.isDesktop ? 'max-width: 860px;' : '')};

  margin: 0 auto;
  ${({ theme }) => theme.isDesktop && 'margin-top: 100px;'};

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 500px) and (max-width: 1280px) {
    justify-content: space-around;
  }
`;

export const Content = styled.div`
  max-width: 520px;

  &.info-limit {
    background-color: ${props => props.theme.textMain};

    a {
      text-decoration: underline;
    }
  }
`;

export const Backdrop = styled.div``;

export const HeaderWrapper = styled.div`
  height: 42px;
  background-color: ${({ theme }) => theme.textPrimary};
  position: relative;
  display: flex;
  align-items: center;
`;

export const DescWrapper = styled.div``;
export const TitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space_10};
  display: flex;
  align-items: center;
`;
export const Header = styled.span<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.tertiaryBackground};
  font-size: ${props => props.theme.fontLG};
  font-weight: ${({ theme }) => theme.fontBold};
  text-transform: uppercase;
`;

export const IconWrap = styled.span<{ color?: string }>`
  margin-right: ${({ theme }) => theme.space_10};
  color: ${({ color }) => color || 'inherit'};
`;

export const ContentInner = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_20}
    ${({ theme }) => theme.space_20};
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.inputDefaultText};
  font-size: ${props => props.theme.fontMD};
  font-stretch: condensed;
  overflow-y: scroll;

  div {
    font-size: ${props => props.theme.fontMD};
    font-weight: ${({ theme }) => theme.fontRegular};
    color: inherit;
    padding-top: ${({ theme }) => theme.space_5};
    height: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.loader-inner {
    min-height: 100px;
    min-width: 400px;
    text-align: center;
    padding: ${({ theme }) => theme.space_20} 0;
  }

  &.limit-inner {
    display: flex;

    div {
      padding-top: 0;
    }
  }
`;

const getBgColor = bgColor => {
  return bgColor ? `background-color: ${bgColor}` : '';
};

export const ButtonInner = styled.div<{ bgColor: string }>`
  padding: 0 ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_20};
  background-color: ${props => props.theme.textMain};

  .limit-layer-btn {
    ${({ bgColor }) => getBgColor(bgColor)}
  }
`;

export const TextWrapper = styled.div`
  margin: ${({ theme }) => theme.space_10} 0 ${({ theme }) => theme.space_20};
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space_15};
`;

export const ContentIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.space_5};
  right: ${({ theme }) => theme.space_15};
  transform: translateY(100%);
  width: 12px;
  height: 12px;
  color: inherit;
  cursor: pointer;
`;

export const Title = styled.h2`
  margin: 0 0 ${({ theme }) => theme.space_10};
  font-weight: ${({ theme }) => theme.fontBold};
  font-size: inherit;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 52%;
  right: calc(50% - ${({ theme }) => theme.space_15});
  bottom: 0;
  height: 30px;
  margin: ${({ theme }) => theme.space_20} 0;
  text-align: center;
  transform: translate(0, -50%);

  > i {
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }
`;
