import { stringify } from 'qs';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';

import history from '@features/core/router/history';
import services from '@features/core/services';
import i18n from '@features/core/translation';

import {
  AUTH_TOKEN,
  BETTING_SLIP,
  SESSION_ID,
  TOKEN,
  QUERY_REG_DATA,
} from '@common/constants/cookie';
import { DYNAMIC_NOTE_LAYER } from '@common/constants/dialogs';
import { MARKETING_COOKIES } from '@common/constants/config';
import {
  createExpiresStamp,
  TWENTY_HOURS_IN_SEK,
} from '@common/helpers/deviceUtil';
import { redirectToSpecificRoute } from '@common/providers/router/helper';
import { IMPORT_BETTINGSLIP } from '@common/providers/bettingslip/state';
import { openSidebar } from '@common/providers/application/useAppState';

const checkForMarketingCookies = (location: Location): null | void => {
  const cookiesService = services.cookie;
  const Logger = services.logger;
  const queryParamsFromHash = location.search.slice(
    location.search.indexOf('?'),
  );
  const urlParams: URLSearchParams = new URLSearchParams(queryParamsFromHash);
  if (location.search) {
    forEach(
      [
        ...(services.config.get(MARKETING_COOKIES) as Array<string>),
        AUTH_TOKEN,
        SESSION_ID,
        IMPORT_BETTINGSLIP,
        QUERY_REG_DATA,
      ],
      cookie => {
        if (
          urlParams.has(cookie) &&
          urlParams.get(cookie) &&
          !cookiesService.get(cookie)
        ) {
          if (cookie === AUTH_TOKEN) {
            const expires = createExpiresStamp(TWENTY_HOURS_IN_SEK);
            cookiesService.set(TOKEN, urlParams.get(cookie) || '', { expires });
          }
          if (cookie === QUERY_REG_DATA) {
            cookiesService.set(QUERY_REG_DATA, urlParams.get(cookie) || '', {
              expires: null,
            });
          }
          if (cookie === IMPORT_BETTINGSLIP) {
            try {
              cookiesService.set(
                BETTING_SLIP,
                window.atob(urlParams.get(cookie) || ''),
                {
                  expires: createExpiresStamp(2629800),
                },
              );
              urlParams.delete(IMPORT_BETTINGSLIP);
              // eslint-disable-next-line lodash/prefer-lodash-method
              history.replace({ search: urlParams.toString() });
              openSidebar({
                type: DYNAMIC_NOTE_LAYER,
                data: {
                  title: i18n.t('bettingslip.reuse_bs_popup.title'),
                  message: cookiesService.get(TOKEN)
                    ? ''
                    : i18n.t('bettingslip.reuse_bs_popup.text'),
                  button: i18n.t('helpers.ok'),
                },
              });
            } catch (e) {
              Logger.log(`Not able to import betting slip ${String(e)}`);
            }
          } else {
            cookiesService.set(cookie, urlParams.get(cookie) || '', {
              expires: createExpiresStamp(2629800),
            });
          }
        }
      },
    );
    if (cookiesService.get(BETTING_SLIP)) {
      return redirectToSpecificRoute(location.pathname);
    }
    if (cookiesService.get(QUERY_REG_DATA)) {
      // maybe need to compare prev data and new params
      return redirectToSpecificRoute(
        `?${stringify(omit(urlParams, ['data']))}`,
      );
    }
    return redirectToSpecificRoute(`?${String(urlParams)}`);
  }
  return null;
};

export default checkForMarketingCookies;
