import styled from 'styled-components';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { TextLarge } from '@features/core/styles/text.styled';

export const Root = styled.div<{
  isTopNotification: boolean;
}>`
  box-sizing: border-box;
  padding-bottom: ${props =>
    props.theme.isDesktop ? '0' : props.theme.space_10};
  background-color: ${props => props.theme.secondary};
  ${({ theme }) => !theme.isDesktop && 'overflow-x: hidden'};
`;

export const FavoritesMobileWrapper = styled.div`
  margin-bottom: ${props => props.theme.space_10};
`;

const getItemBackground = (isFavorite, isOdd, theme) => {
  if (isOdd) {
    return isFavorite ? theme.elementNuanceColor : theme.textActive;
  }
  return isFavorite ? theme.textActive : theme.elementNuanceColor;
};

export const Item = styled.div<{ isFavorite: boolean }>`
  &:nth-child(odd) {
    background-color: ${({ isFavorite, theme }) =>
      theme.isDesktop
        ? theme.elementNuanceColor
        : getItemBackground(isFavorite, true, theme)};
  }

  &:nth-child(even) {
    background-color: ${({ isFavorite, theme }) =>
      theme.isDesktop
        ? theme.textActive
        : getItemBackground(isFavorite, false, theme)};
  }
`;

export const EmptyList = styled.div`
  padding: ${props => props.theme.space_10};
  background-color: ${props =>
    props.theme.isDesktop
      ? props.theme.textActive
      : props.theme.elementNuanceColor};
  color: ${props => props.theme.textMain};
`;

export const EmptyListTitle = styled(TextLarge)`
  margin: 0 0 ${props => props.theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const EmptyListMessage = styled(InfoBlock)`
  margin: 0;
  color: ${props => props.theme.textMain};
  font-size: ${props => props.theme.fontMD};
  text-align: left;
`;
