import { Socket } from 'phoenix';

import services from '@features/core/services';

import { BET_RM_SOCKET_URL } from '@common/constants/config';
import { SESSION_ID, USER_ID } from '@common/constants/cookie';
import { resetBetsSocket } from '@common/providers/bets/helper';
import {
  removeOpenBetsItem,
  resetUpdates,
  setBetsSocketConnection,
  setBetsSocketData,
  setOpenBetStatus,
  setSocketReconnect,
} from '@common/providers/bets/useBets';
import { getWallets } from '@common/providers/payments/wallets/helpers';

// attemp 4 = 16000 ms (16 seconds) (last and maximum delay)
export const MAX_RECONNECT_ATTEMPTS = 4;
const INITIAL_RECONNECT_INTERVAL = 1000;
let reconnectionAttempts = 0;
let reconnectTimeout;

const createConnection = (connect, reset?: boolean): void => {
  const userId = services.cookie.get(USER_ID);
  const session_id = services.cookie.get(SESSION_ID);
  const socket = new Socket(services.config.get(BET_RM_SOCKET_URL), {
    params: { user: userId },
    heartbeatIntervalMs: 10000,
  });
  setBetsSocketConnection(socket);
  connect(socket, userId, session_id);

  if (reset) {
    reconnectionAttempts = 0;
    setSocketReconnect(false);
  }
};

export const reconnect = (socket, connect): void => {
  if (socket) {
    resetBetsSocket(socket, true);
    resetUpdates();
  }
  if (reconnectionAttempts <= MAX_RECONNECT_ATTEMPTS) {
    reconnectionAttempts++;
    reconnectTimeout = setTimeout(() => {
      if (reconnectionAttempts === MAX_RECONNECT_ATTEMPTS) {
        setSocketReconnect(true);
      }
      services.logger.log(
        `Bets Socket Reconnection Attemps: ${reconnectionAttempts + 1}`,
      );
      createConnection(connect);
    }, 2 ** reconnectionAttempts * INITIAL_RECONNECT_INTERVAL);
  } else {
    clearTimeout(reconnectTimeout);
  }
};

export const connect = (socket, userId, session_id): void => {
  try {
    socket.connect();
    socket.onOpen(() => {
      services.logger.log('bets socket open', new Date().toString());
    });
    socket.onError(() => {
      reconnect(socket, connect);
      services.logger.log('Error connecting to socket');
    });
    const userChannel = socket.channel(`user:${userId}`, { session_id });
    userChannel
      .join()
      .receive('ok', () => {
        clearTimeout(reconnectTimeout);
        services.logger.log('joined chanel');
        reconnectionAttempts = 0;
        setSocketReconnect(false);
      })
      .receive('error', () => {
        reconnect(socket, connect);
        services.logger.log('Failed to join user channel');
      });

    userChannel.onError(() => {
      reconnect(socket, connect);
      services.logger.log('Error connecting to user channel');
    });

    userChannel.on('offer', setBetsSocketData);
    userChannel.on('selection_result_updated', setOpenBetStatus);
    userChannel.on('bet_settled', ({ bet_id }) => {
      removeOpenBetsItem(bet_id);
      getWallets();
    });
  } catch (e) {
    services.logger.log('Error connecting to socket', new Date().toString());
  }
};

export default createConnection;
