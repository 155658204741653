import styled from 'styled-components';

export const eventHeaderDesktopStyles = ({ theme }) => `
  z-index: 22;
  top: ${theme.space_25};
`;

export const GameId = styled.div<{ isLiveFootball: boolean }>`
  color: ${({ theme }) => theme.textMain};
  opacity: 0.65;
  font-size: ${({ theme }) => theme.fontSM};
  padding: 0 ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_10}
    ${({ theme }) => theme.space_15};
  display: grid;
  justify-content: ${({ isLiveFootball }) =>
    isLiveFootball ? 'center' : 'start'};
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.space_5};
  align-items: center;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.elementNuanceColor};
  position: ${({ theme }) => (theme.isDesktop ? 'sticky' : 'relative')};
  ${({ theme }) => theme.isDesktop && eventHeaderDesktopStyles}
  overflow: hidden;

  .mi-info-circle {
    color: ${({ theme }) => theme.inputDefaultText};
  }
`;

export const detailEventWrapperDesktopStyles = borderColor => `
  border-left: 2px solid ${borderColor};
  height: 100%;
`;

export const DetailEventContent = styled.div`
  ${({ theme }) => !theme.isDesktop && 'height: 100%'};
  ${({ theme }) => !theme.isDesktop && 'overflow-x: hidden'};
  background-color: ${({ theme }) =>
    theme.isDesktop ? theme.textActive : theme.bodyBackground};
  ${({ theme }) =>
    theme.isDesktop && detailEventWrapperDesktopStyles(theme.accent)}

  & .not-started-timer {
    margin-right: ${({ theme }) => theme.space_5};
  }
`;

export const headerWithGenericScore = ({ theme }) => `
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "team score timer";
  grid-gap: ${theme.space_10};
  align-items: flex-start;

  .team {
    grid-area: team;
  }

  .timer {
    grid-area: timer;
    justify-self: end;
    margin-right: ${theme.space_10};
  }

  span {
    margin: 0;
    max-width: min-content;
  }
`;

export const marketPadding = ({ theme }) =>
  theme.isDesktop
    ? `${theme.space_5} ${theme.space_5} ${theme.space_5} ${theme.space_10};`
    : `0 0 0 ${theme.space_10}`;

export const MarketAnimationWrapper = styled.div<{
  isBetPackerMarketActive?: boolean;
  maxHeight: string;
}>`
  max-height: ${({ maxHeight }) => maxHeight};
  ${({ isBetPackerMarketActive }) =>
    !isBetPackerMarketActive && 'max-height: 0px; opacity: 0;'}
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
`;

const mobilePaddingStyles = ({ theme }) =>
  `${theme.space_5} 0 ${theme.space_5} ${theme.space_10};`;

export const Market = styled.div<{ isEven?: boolean; hasWinner: boolean }>`
  background-color: ${({ theme, isEven }) =>
    isEven ? theme.textActive : theme.tertiaryBackground};
  padding: ${({ hasWinner }) => (hasWinner ? '0' : marketPadding)};
  border-bottom: ${({ theme }) => theme.border};

  @media screen and (max-width: 481px) {
    padding: ${({ hasWinner }) => !hasWinner && mobilePaddingStyles};
  }
`;

export const MarketsWrapper = styled.div`
  margin-bottom: ${({ theme }) => (theme.isDesktop ? '0' : theme.space_50)};
`;

export const IconWrapper = styled.div`
  padding: ${({ theme }) => theme.space_10};
  background-color: ${props => props.theme.tertiaryBackground};
  text-align: right;
  position: sticky;
  top: 0;
  z-index: 22;
  max-height: 24px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;

  & svg {
    & path {
      fill: ${props => props.theme.textMain};
    }

    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`;
