import find from 'lodash/find';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import replace from 'lodash/replace';
import head from 'lodash/head';
import toLower from 'lodash/toLower';
import compact from 'lodash/compact';

import services from '@features/core/services';

import { HAS_BONUS_WALLETS, HAS_SHOP_E_MONEY } from '@common/constants/config';
import { fixDecimals } from '@common/helpers/deviceUtil';
import { isShopUser } from '@common/helpers/userHelper/userHelper';
import { IPaymentOperation, IWallet } from '@common/interfaces';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';
import { useUserState } from '@common/providers/user/useUserState';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { IBSWalletType } from '@common/interfaces/bettingslip/IBettingslip';

export const getWallet = (
  affiliate_id: string,
  walletType: IUserWalletType = IUserWalletType.www,
): IWallet | undefined => {
  const wallets = useWalletsState.getState().data;
  const user = useUserState.getState().data;
  if (walletType === IUserWalletType.bonus) {
    return find(wallets, {
      type: IUserWalletType.bonus,
      affiliate_id,
    });
  }
  return find(
    wallets,
    wallet =>
      wallet.type !== IUserWalletType.bonus &&
      wallet.affiliate_id === affiliate_id &&
      user?.username === wallet.username,
  );
};

export const getWalletName = (wallet: IWallet): string =>
  replace(
    (wallet.label || wallet.name) as string,
    /^Shop Emoney( \d+)?(-| )(\d+)?|^(\d+)(-| )(\d+)/,
    '',
  );

export const getWalletBalance = (
  affiliate_id: string,
  walletType: IUserWalletType = IUserWalletType.www,
): string => {
  const wallet = getWallet(affiliate_id, walletType);
  return wallet?.balance || '0';
};

export const getTotalBalance = (wallet: IWallet): number => {
  if (parseFloat(wallet.consolidated_balance as string)) {
    return parseFloat(wallet.consolidated_balance as string);
  }
  return parseFloat(wallet.balance);
};

export const getActiveAffiliateId = (): string | undefined => {
  const user = useUserState.getState().data;
  return user?.affiliateID;
};

export const getActiveWallet = (
  walletType: IUserWalletType = IUserWalletType.www,
): IWallet | undefined => {
  const affiliateId = getActiveAffiliateId();
  if (!affiliateId) {
    return undefined;
  }
  return getWallet(affiliateId, walletType);
};

export const getActiveWalletBalance = (
  walletType: IUserWalletType = IUserWalletType.www,
): number => parseFloat(getActiveWallet(walletType)?.balance || '0');

/**
 * getCurrencyFromCode
 *
 * @returns {string} currency
 */
export const getCurrencyFromCode = (): string => {
  const user = useUserState.getState().data;
  const currencyCode = user?.currencyCode || 'EUR';
  const lower = toLower(currencyCode || '');
  if (lower === 'eur' || lower === '&#8364;') {
    return '€';
  }
  if (lower === 'lek' || lower === 'all' || lower === 'alle') {
    return 'LEK';
  }
  return currencyCode || '';
};

export const formatBalance = (
  balance: number | string,
  options?: {
    reversePresentation?: boolean;
    precision?: number;
    optional?: number;
  },
): string =>
  options?.reversePresentation
    ? `${getCurrencyFromCode()} ${fixDecimals(balance, {
        optional: options?.optional,
        precision: options?.precision,
      })}`
    : `${fixDecimals(balance, {
        optional: options?.optional,
        precision: options?.precision,
      })} ${getCurrencyFromCode()}`;

export const formatWalletBalance = (
  wallet: IWallet | undefined,
  isTotal = false,
): string =>
  !wallet
    ? formatBalance(0)
    : formatBalance(isTotal ? getTotalBalance(wallet) : wallet.balance);

export const hasBonusWallets = (wallet?: IWallet): boolean => {
  const activeWallet = getActiveWallet();
  return (
    !!services.config.get(HAS_BONUS_WALLETS) &&
    !!parseFloat((wallet || activeWallet)?.bonus_enabled as string)
  );
};

export const hasShopEMoneyWallets = (): boolean => {
  const wallets = useWalletsState.getState().data;
  return (
    !!services.config.get(HAS_SHOP_E_MONEY) &&
    !!find(wallets, { type: IUserWalletType.shop_emoney })
  );
};

export const hasShopWallets = (): boolean => {
  const wallets = useWalletsState.getState().data;
  return (
    !!services.config.get(HAS_SHOP_E_MONEY) &&
    !!find(wallets, { type: IUserWalletType.is_outletcash_only })
  );
};

export const getShopEMoneyWallets = (): IWallet[] => {
  const wallets = useWalletsState.getState().data;
  return filter(wallets, { type: IUserWalletType.shop_emoney });
};

export const getShopyWallets = (): IWallet[] => {
  const wallets = useWalletsState.getState().data;
  return filter(wallets, { type: IUserWalletType.is_outletcash_only });
};

export const getShopEMoneyBalance = (): number => {
  const wallets = useWalletsState.getState().data;
  return reduce(
    wallets,
    (acc, wallet) =>
      wallet.type === IUserWalletType.shop_emoney
        ? acc + parseFloat(wallet.balance)
        : acc,
    0,
  );
};

export const hasBettingSlipWalletSwitcher = (): boolean => {
  const realMoney = getActiveWalletBalance();
  const bonusMoney = getActiveWalletBalance(IUserWalletType.bonus);
  const EMoney = getShopEMoneyBalance();
  const hasBonusWallet = hasBonusWallets() && !!bonusMoney;
  const hasShopWallet = hasShopEMoneyWallets() && !!EMoney;
  const walletTypesCounter =
    Number(!!realMoney) + Number(hasBonusWallet) + Number(hasShopWallet);

  return walletTypesCounter > 1 && !isShopUser();
};

export const getAllowedExistingWallets = (): IBSWalletType[] => {
  const realMoney = getActiveWalletBalance();
  const bonusMoney = getActiveWalletBalance(IUserWalletType.bonus);
  const EMoney = getShopEMoneyBalance();
  const hasBonusWallet = hasBonusWallets() && !!bonusMoney;
  const hasShopWallet = hasShopEMoneyWallets() && !!EMoney;

  return compact([
    !!realMoney && IBSWalletType.REGULAR,
    hasShopWallet && IBSWalletType.SHOP,
    hasBonusWallet && IBSWalletType.BONUS,
  ]);
};

export const getDefaultBettingslipWallet = (): IBSWalletType => {
  const realMoney = getActiveWalletBalance();
  const bonusMoney = getActiveWalletBalance(IUserWalletType.bonus);
  const EMoney = getShopEMoneyBalance();
  const hasBonusWallet = hasBonusWallets() && !!bonusMoney;
  const hasShopWallet = hasShopEMoneyWallets() && !!EMoney;

  if (hasShopWallet) {
    return IBSWalletType.SHOP;
  }

  if (hasBonusWallet && !realMoney) {
    return IBSWalletType.BONUS;
  }

  return IBSWalletType.REGULAR;
};

export const getBettingSlipBalance = (): number => {
  const { walletType } = useBettingSlip.getState();

  const realMoney = getActiveWalletBalance();
  const bonusMoney = getActiveWalletBalance(IUserWalletType.bonus);
  const eMoneyBalance = getShopEMoneyBalance();

  if (walletType === IBSWalletType.BONUS) {
    return bonusMoney;
  }
  if (walletType === IBSWalletType.SHOP) {
    return eMoneyBalance;
  }
  return realMoney;
};

export const getPaymentWallet = (
  operation: string,
  selectedWallet?: IWallet,
): IWallet | undefined => {
  if (selectedWallet && operation === IPaymentOperation.DEPOSIT) {
    return find(getShopyWallets(), ['account_id', selectedWallet.account_id]);
  }
  return head(
    operation === IPaymentOperation.DEPOSIT
      ? getShopyWallets()
      : getShopEMoneyWallets(),
  );
};
