import React, { useState, useEffect, useMemo } from 'react';
import some from 'lodash/some';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useEventsModel } from '@features/events/useEventsModel';
import services from '@features/core/services';

import { MarketingEvents } from '@packages/events/appEvents';

import { MAX_BS_SELECTIONS } from '@common/constants/config';
import { ISelection, PageType } from '@common/interfaces';
import {
  isEnabled as isMarketEnabled,
  containsDisabledPredictions,
} from '@common/helpers/markets/marketModel';
import {
  getFormattedOdds,
  isEnabled,
  getShortLabel,
} from '@common/helpers/eventsHelper/predictionModel';
import {
  getLiveStatus,
  isWinner,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import { BetView } from '@common/providers/bettingslip/types';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import {
  addBetPackerSelection,
  addSelection,
  removeBetPackerSelection,
  removeSelection,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import useOddsDirection from '@common/hooks/useOddsDirection';

import { Icon } from '@ui/components/icon';

import { Props } from './Prediction.types';
import * as S from './Prediction.styled';

const Prediction = (props: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const { selectTextColor } = useTheme();

  const {
    eventId,
    marketId,
    predictionId,
    listType,
    pageType,
    withLabel,
  } = props;

  const market = useEventsListState(s => s[listType].data.markets[marketId]);
  const prediction = useEventsListState(
    s => s[listType].data.predictions[predictionId],
  );
  const bsMode = useBettingSlip(state => state.bsMode);
  const state = useBettingSlip(s => s.state);
  const betPackerPredictions = useBettingSlip(s => s.betPackerPredictions);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const selections = useBettingSlip(s => s.selections);
  const loading = useBettingSlip(s => s.loading);
  const { event, category } = useEventsModel(eventId, listType);
  const { up, down } = useOddsDirection(prediction);
  const [selected, setSelected] = useState(false);
  const kombiSelected = useMemo(
    () => some(betPackerSelections, ['id', prediction.id]),
    [betPackerSelections, prediction],
  );

  useEffect(() => {
    setSelected(some(selections, ['id', prediction.id]));
  }, [selections, prediction]);

  const toggleSelection = (ev): void | null | boolean => {
    ev.preventDefault();
    ev.stopPropagation();
    if (state === STATE_FROZEN || state === STATE_QUEUED || loading) {
      return false;
    }
    prediction.category = category;
    prediction.market = market;
    prediction.event = event;
    if (
      selections.length >= (services.config.get(MAX_BS_SELECTIONS) as number) &&
      !selected
    ) {
      return null;
    }
    if (bsMode === BetView.BETPACKER) {
      if (kombiSelected) {
        return removeBetPackerSelection(prediction as ISelection);
      }
      return addBetPackerSelection(prediction as ISelection);
    }
    if (selected) {
      setSelected(false);
      removeSelection(prediction as ISelection);
      return null;
    }
    setSelected(true);

    services.events.emitEvent(MarketingEvents.SELECTION, {
      listType,
      ...prediction,
    });
    return addSelection(prediction as ISelection);
  };

  const getLabel = (): string => getShortLabel(prediction, market.type);

  if (
    prediction &&
    isEnabled(prediction) &&
    isMarketEnabled(market, getLiveStatus(event)) &&
    (bsMode !== BetView.BETPACKER ||
      includes(betPackerPredictions, prediction.id) ||
      kombiSelected)
  ) {
    const selectedInMode =
      bsMode === BetView.BETPACKER ? kombiSelected : selected;
    return (
      <S.StyledButton
        selected={selectedInMode}
        up={up}
        down={down}
        onClick={toggleSelection}
        className={`${getFormattedOdds(prediction).length > 5 ? 'small' : ''}${
          selected ? 'selected' : ''
        } ${getLabel().length > 3 ? 'long-label' : ''}`}
        hasWinner={!!isWinner(event)}
        aria-label={`Prediction ${getFormattedOdds(prediction)}`}
        data-qa={`prediction-btn-${getLiveStatus(event)}`}
        isDetail={pageType === PageType.DETAIL}
        translate="no"
      >
        {(pageType === PageType.DETAIL || withLabel) && (
          <S.PredictionsLabel translate="no" selected={selectedInMode}>
            {getLabel()}
          </S.PredictionsLabel>
        )}
        <span translate="no">{getFormattedOdds(prediction)}</span>
      </S.StyledButton>
    );
  }

  if (prediction && !containsDisabledPredictions(market)) {
    return <></>;
  }

  if (prediction) {
    return (
      <S.StyledButton
        isDisabled
        aria-label={t('common.buttons.prediction_close')}
        className="disabled"
        isDetail={pageType === PageType.DETAIL}
      >
        {pageType === PageType.DETAIL && getLabel()}
        <Icon
          width="23"
          height="13.6"
          name="lock-outlined"
          fill={selectTextColor}
        />
      </S.StyledButton>
    );
  }
  return <></>;
};

export default Prediction;
