import find from 'lodash/find';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';

import services from '@features/core/services';

import { ICMSContentOfAffiliate } from '@common/providers/application/types';
import { getPartnerId } from '@common/helpers/registrationHelper/registrationHelpers';
import {
  setAffiliateParams,
  useAppState,
} from '@common/providers/application/useAppState';
import { useUserState } from '@common/providers/user/useUserState';
import getAffiliateParams from '@common/providers/user/actions/getAffiliateParams';
import { MARKETING_COOKIES } from '@common/constants/config';

const getValue = (
  key: string,
  partnersData: ICMSContentOfAffiliate,
): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    partnersData[key] || services.cookie.get(key) || urlParams.get(key) || ''
  );
};

const getPartnersValue = (
  partnersData: ICMSContentOfAffiliate,
): ICMSContentOfAffiliate =>
  reduce(
    services.config.get(MARKETING_COOKIES) as Array<string>,
    (acc, key) => {
      if (getValue(key, partnersData)) {
        return assign(acc, { [key]: getValue(key, partnersData) });
      }
      return acc;
    },
    {},
  );

export const getAffiliateParamsByUsername = (): ICMSContentOfAffiliate => {
  const { affiliateParams } = useAppState.getState();
  const user = useUserState.getState().data;
  const partnersData =
    find(affiliateParams, {
      username: user?.username,
    }) || {};

  return getPartnersValue(partnersData);
};

export const getAffiliateParamsById = (): ICMSContentOfAffiliate => {
  const { pathname } = window.location;
  const affiliateId = getPartnerId(pathname);
  const { affiliateParams } = useAppState.getState();
  const partnersData = (affiliateParams && affiliateParams[affiliateId]) || {};

  return getPartnersValue(partnersData);
};

export const getAffiliateParamsData = async (): Promise<void> => {
  const { affiliateParams } = useAppState.getState();
  if (affiliateParams) {
    return;
  }
  const affiliateData = await getAffiliateParams();
  setAffiliateParams(affiliateData);
};
