import React, { useEffect, useRef, useState } from 'react';

import { BetStats } from '@features/cashout/components/betStats';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import SubmitHoc from '@features/cashout/hoc/SubmitHoc';
import CashoutMaxButton from '@features/cashout/components/cashoutMaxButton/CashoutMaxButton';
import PartCashoutButtons from '@features/cashout/components/partCashoutButtons/PartCashoutButtons';

import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  ButtonSize,
  ButtonType,
  ICashoutInputProps,
  TTimeout,
} from '@common/interfaces';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_QUEUED,
} from '@common/providers/cashout/state';
import deleteTimeout from '@common/helpers/timeoutHelper';
import {
  cashoutDefaultButtonValue,
  formatCashoutOffer,
  getNewRisk,
  getNewStake,
} from '@common/helpers/cashoutHelper';
import { useBets } from '@common/providers/bets/useBets';
import useCountDown from '@common/hooks/useCountDown';

import { StyledButton } from '@ui/components/buttons';

import * as S from './CashoutInputs.styled';

const CashoutInputs: React.FC<ICashoutInputProps> = ({
  betId,
  submit,
  onSubmitCashoutQueued,
  cashoutOfferQueued,
  scrollToElem,
}) => {
  const updatesReceived = useBets(s => s.updatesReceived);
  const {
    cashoutOffer,
    requestedCashout,
    cashoutDisabled,
    totalStake,
    remainingRisk,
  } = useCashoutItemModel(betId);
  const { countdown, startCountDown } = useCountDown();
  const [partValue, setPartValue] = useState('0');
  const [partExtended, setPartExtended] = useState<boolean>(false);
  const [readySubmitMax, setReadySubmitMax] = useState<boolean>(false);
  const [readySubmitPart, setReadySubmitPart] = useState<boolean>(false);
  const timeoutScrollRef = useRef<TTimeout>(null);
  const state = requestedCashout && requestedCashout.state;
  const isQueued = state === STATE_QUEUED;

  const getCashoutValue = (isMax?: boolean): number | string => {
    const cashoutValue = isMax ? 0 : cashoutOffer;
    if (cashoutOffer === cashoutDefaultButtonValue) {
      return cashoutOffer;
    }
    return updatesReceived
      ? parseFloat(cashoutOfferQueued || cashoutOffer)
      : cashoutValue;
  };

  const newCashoutOffer = getCashoutValue();
  const maxValue = getCashoutValue(true) as number;

  useEffect(() => {
    return () => deleteTimeout(timeoutScrollRef.current);
  });

  useEffect(() => {
    if (state === STATE_ERROR || state === STATE_ACCEPTED) {
      setReadySubmitMax(false);
      setReadySubmitPart(false);
    }
  }, [requestedCashout]);

  useEffect(() => {
    if (requestedCashout?.queue_delay) {
      startCountDown(
        () => onSubmitCashoutQueued(requestedCashout),
        requestedCashout.queue_delay,
      );
    }
  }, [requestedCashout?.queue_delay]);

  return (
    <div className={`cashout-button cashout-el-bg-${betId}`}>
      <div className="partial-wrapper">
        <S.MainAmount
          className="cashout-container cashout-reverse-bg"
          id={`cashout-container-${betId}`}
        >
          <CashoutMaxButton
            betId={betId}
            readySubmitPart={readySubmitPart}
            readySubmitMax={readySubmitMax}
            countdown={countdown}
            newCashoutOffer={newCashoutOffer}
            partValue={partValue}
            setReadySubmitMax={setReadySubmitMax}
            setReadySubmitPart={setReadySubmitPart}
            submit={submit}
          />

          {!cashoutDisabled && (
            <StyledButton
              disabled={
                !formatCashoutOffer(newCashoutOffer, cashoutDisabled) ||
                isQueued
              }
              onClick={(): void => {
                setPartExtended(!partExtended);

                if (!isDesktopView()) {
                  setTimeout(() => {
                    scrollToElem();
                  }, 300);
                }
              }}
              label="cashout.cashout_label"
              buttonType={ButtonType.LightGhost}
              className="partly-cashout-btn"
              size={ButtonSize.Big}
            />
          )}

          <PartCashoutButtons
            betId={betId}
            partExtended={partExtended}
            partValue={partValue}
            readySubmitPart={readySubmitPart}
            readySubmitMax={readySubmitMax}
            setPartValue={setPartValue}
            setReadySubmitPart={setReadySubmitPart}
            setReadySubmitMax={setReadySubmitMax}
            countdown={countdown}
            submit={submit}
            maxValue={maxValue}
            newCashoutOffer={newCashoutOffer}
          />
        </S.MainAmount>

        {+partValue !== 0 && !cashoutDisabled && (
          <div className="test">
            <BetStats
              current={false}
              remainingRisk={String(
                getNewRisk(partValue, maxValue, remainingRisk),
              )}
              totalStake={String(getNewStake(partValue, maxValue, totalStake))}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { CashoutInputs as Component };

export default SubmitHoc(CashoutInputs);
