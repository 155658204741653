import reduce from 'lodash/reduce';
import find from 'lodash/find';
import join from 'lodash/join';
import keys from 'lodash/keys';

import { PageName } from '@features/core/routing';

import {
  EventTimeFilters,
  ILiveSportCategoriesTreeData,
} from '@common/interfaces';
import {
  getItemFilterQuery,
  processChildren,
} from '@common/helpers/categories/categoriesModel';
import { setLocation } from '@common/providers/router/helper';

export const getCategoriesBySport = (
  sportCategories: string[],
  sportCategoriesTree: ILiveSportCategoriesTreeData[],
  isDesktop: boolean,
  isLongTerm?: boolean,
): ILiveSportCategoriesTreeData[] =>
  reduce(
    sportCategories,
    (acc: ILiveSportCategoriesTreeData[], item: string) => {
      const sport = find(sportCategoriesTree, ['id', item]);
      if (sport && sport.children && !isDesktop) {
        return acc.concat(processChildren(sport.children, 2));
      }
      if (sport && sport.children && isLongTerm) {
        return acc.concat(processChildren(sport.children, 2));
      }
      if (sport && sport.children) {
        return acc.concat(sport.children);
      }
      return acc;
    },
    [],
  );

const getAllEventsLinkSearch = (
  currentCategories: ILiveSportCategoriesTreeData[],
): string => {
  const currentCategoriesIds: string[] = reduce(
    currentCategories,
    (acc: string[], item: ILiveSportCategoriesTreeData) => {
      acc.push(item.id);
      return acc;
    },
    [] as string[],
  );
  return join(currentCategoriesIds, ',');
};

type IAllEventsLinkProps = {
  isFavoriteSection: boolean;
  currentCategories: ILiveSportCategoriesTreeData[];
  favoritesCategories: Record<string, string>;
  isLongTerm?: boolean;
  isHighlights?: boolean;
  selectedSportCategory: string;
  timeFilter: keyof typeof EventTimeFilters | '';
};
export const onAllEventsLink = (props: IAllEventsLinkProps): void => {
  const {
    isFavoriteSection,
    currentCategories,
    favoritesCategories,
    isLongTerm,
    isHighlights,
    selectedSportCategory,
    timeFilter,
  } = props;
  const currentFavoriteCategories = keys(favoritesCategories).join();

  let search;
  if (isFavoriteSection) {
    search = `?categories=${currentFavoriteCategories}`;
  } else if (isLongTerm || isHighlights) {
    search = `?categories=${getAllEventsLinkSearch(currentCategories)}`;
  } else {
    search = '';
  }

  setLocation(
    PageName.EVENTS_CATEGORY,
    {
      categoryId: selectedSportCategory,
      filter: getItemFilterQuery(timeFilter),
    },
    { forceReload: true, search },
  );
};
