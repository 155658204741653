import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing';
import services from '@features/core/services';

import { WALLET_SWITCHER } from '@common/constants/dialogs';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { setLocation } from '@common/providers/router/helper';
import { closeSidebar } from '@common/providers/application/useAppState';
import { IWallet } from '@common/interfaces';
import {
  hasBonusWallets,
  getWallet,
  formatWalletBalance,
  hasShopEMoneyWallets,
  getShopEMoneyBalance,
  getWalletName,
  formatBalance,
} from '@common/helpers/paymentsHelper/walletsHelper';
import { HAS_LUGAS } from '@common/constants/config';

import { Icon } from '@ui/components/icon';
import { ButtonType } from '@ui/components/buttons/Button.styled';

import * as S from './WalletSwitcherItem.styled';
import { IWalletSwitcherItem } from './WalletSwitcherItem.types';
import Description from './description/Description';
import CurrencyIcon from './currencyIcon/CurrencyIcon';

const getWalletTypeData = (
  wallet: IWallet,
  dataType: string,
  t,
): string | JSX.Element => {
  const passedType = wallet.type;
  const passedName = getWalletName(wallet);

  let label: JSX.Element | string = t('wallet_switcher.online_account');
  let iconName = 'switcher-credit-card';
  if (passedType === IUserWalletType.www) {
    iconName = 'switcher-global';
  }
  if (passedType === IUserWalletType.casino) {
    label = t('wallet_switcher.online_slots');
  }
  if (passedType === IUserWalletType.is_outletcash_only) {
    label = (
      <>
        {t('wallet_switcher.online_outletcash')}
        <br />
        {passedName && (
          <span>
            (<small>{passedName}</small>)
          </span>
        )}
      </>
    );
  }
  return dataType === 'label' ? label : iconName;
};

const WalletSwitcherItem: React.FC<IWalletSwitcherItem> = ({
  switchAccount,
  isSwitcherPopup,
  wallet,
  isActive,
  isSingleWallet,
}) => {
  const { t } = useTranslation();
  const isDesktop = isDesktopView();
  const isWalletLabelPresented =
    IUserWalletType.is_outletcash_only === wallet.type && !!wallet.label;
  const bonusWallet = getWallet(wallet.affiliate_id, IUserWalletType.bonus);
  const hasLugas = services.config.get(HAS_LUGAS);

  const handleButtonClick = (): void => {
    setLocation(PageName.USER_PAYIN);
    closeSidebar(WALLET_SWITCHER);
  };

  return (
    <S.WalletRow
      isSingleWallet={isSingleWallet}
      isSwitcherPopup={!!isSwitcherPopup}
      isActive={isActive}
      onClick={
        (): void | null =>
          !isActive && switchAccount ? switchAccount(wallet) : null
        // eslint-disable-next-line react/jsx-curly-newline
      }
      role="presentation"
      hasLugas={!!hasLugas}
    >
      <S.WalletHeader
        isSwitcherPopup={isSwitcherPopup}
        isWalletLabelPresented={isWalletLabelPresented}
      >
        <S.WalletInfoIcon
          width="18"
          height="18"
          name={getWalletTypeData(wallet, 'iconName', t) as string}
        />
        <S.WalletInfoLabel>
          {getWalletTypeData(wallet, 'label', t)}
        </S.WalletInfoLabel>
        {isActive && !isSwitcherPopup && (
          <S.ActiveWalletMark>
            {t('wallet_switcher.active_account')}
          </S.ActiveWalletMark>
        )}
      </S.WalletHeader>
      <S.WalletTotalBalance>
        {formatWalletBalance(wallet, true)}
      </S.WalletTotalBalance>

      <S.WalletBalanceWrapper>
        <S.WalletLabel>
          <CurrencyIcon />
          <S.LabelText>{t('wallet_switcher.real_money')}</S.LabelText>
        </S.WalletLabel>
        <S.WalletBalance>{formatWalletBalance(wallet)}</S.WalletBalance>
        {hasBonusWallets(wallet) && (
          <>
            <S.WalletLabel>
              <Icon name="gift" width="13" height="16" />
              <S.LabelText>{t('wallet_switcher.bonus_money')}</S.LabelText>
            </S.WalletLabel>
            <S.WalletBalance>
              {formatWalletBalance(bonusWallet as IWallet)}
            </S.WalletBalance>
          </>
        )}
        {hasShopEMoneyWallets() && wallet.type === IUserWalletType.www && (
          <>
            <S.WalletLabel hasTopMargin>
              <Icon name="credit-card" width="13" height="16" />
              <S.LabelText>{t('wallet_switcher.shop_money')}</S.LabelText>
            </S.WalletLabel>
            <S.WalletBalance hasTopMargin>
              {formatBalance(getShopEMoneyBalance())}
            </S.WalletBalance>
          </>
        )}
      </S.WalletBalanceWrapper>
      {isSwitcherPopup && hasLugas && (
        <Description isOnline={wallet.type === IUserWalletType.www} />
      )}
      {isActive &&
        !isSwitcherPopup &&
        !isDesktop &&
        wallet.type !== IUserWalletType.is_outletcash_only && (
          <S.ActiveWalletButton
            buttonType={ButtonType.Secondary}
            onClick={handleButtonClick}
          >
            {t('payments.button_deposit')}
          </S.ActiveWalletButton>
        )}
    </S.WalletRow>
  );
};

export default WalletSwitcherItem;
