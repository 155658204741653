/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import head from 'lodash/head';

import * as S from '@pages/login/Login.styled';

import { InfoBlock } from '@features/profile/components/infoBlock';

import { GCMS_FORM_SUBMISSION } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import {
  closeSidebar,
  useAppState,
} from '@common/providers/application/useAppState';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

const GCMSLayer = (): React.ReactElement => {
  const { t } = useTranslation();
  const { statusSuccess, statusError } = useTheme();
  const openedSidebars = useAppState(s => s.openedSidebars);
  const { title, content } = GCMSLayer.meta.props;

  const sidebar = head(openedSidebars);

  const handleClose = (): void => {
    closeSidebar(sidebar?.type as string);
  };

  const status = sidebar?.type === GCMS_FORM_SUBMISSION ? 'success' : 'error';
  const color =
    sidebar?.type === GCMS_FORM_SUBMISSION ? statusSuccess : statusError;

  return (
    <S.Content className="popup-wrapper">
      <S.ContentInner className="popup-inner">
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.TitleWrapper>
          <S.IconWrap color={color}>
            <Icon name="check-circle" width="28px" height="28px" />
          </S.IconWrap>
          <S.Header
            color={color}
            dangerouslySetInnerHTML={{
              __html: title || t(`gcmsPopup.title.${status}`),
            }}
          />
        </S.TitleWrapper>
        <InfoBlock message={content || t(`gcmsPopup.content.${status}`)} />
      </S.ContentInner>
      <S.ButtonInner bgColor={color}>
        <StyledButton
          className="popup-button"
          onClick={(e): void => {
            e.preventDefault();
            handleClose();
          }}
          label="helpers.ok"
          buttonType={ButtonType.Secondary}
        />
      </S.ButtonInner>
    </S.Content>
  );
};

GCMSLayer.meta = {
  side: SidebarSide.center,
  key: GCMS_FORM_SUBMISSION,
  props: {
    type: '',
    title: '',
    content: '',
  },
};

export { GCMSLayer as Component };

export default GCMSLayer;
