import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const AmountButtonWrapper = styled.div<{ noGap: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr min-content;
  ${({ noGap }) => (noGap ? '' : 'grid-gap: 10px;')}

  button {
    max-width: 100vw;
    padding: ${({ theme }) => theme.space_10};
    justify-self: end;
  }
`;
