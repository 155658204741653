import React from 'react';
import { useTranslation } from 'react-i18next';

import { ICategoriesListTitleProps } from '@features/categories/categoriesList/CategoriesList.types';
import * as S from '@features/categories/categoriesList/CategoriesListTitle.styled';

import { onAllEventsLink } from '@common/helpers/categories/categoriesListHelper';
import { useFavoritesState } from '@common/providers/favorites/useFavoritesState';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';

const CategoriesListTitle = (props: ICategoriesListTitleProps): JSX.Element => {
  const { t } = useTranslation();
  const favoritesCategories = useFavoritesState(s => s.favoritesCategories);
  const selectedSportCategory = useLiveCategories(
    s => s.selectedSportCategory[0],
  );
  const {
    isFavoriteSection,
    isHighlights,
    hasFavorite,
    isLongTerm,
    currentCategories,
    timeFilter,
  } = props;
  let title;

  if (isFavoriteSection) {
    title = t('common.labels.favorites');
  } else if (isHighlights) {
    title = t('common.labels.highlights');
  } else {
    title = t('common.labels.categories');
  }
  return (
    <S.Title>
      <span>{t(title)}</span>

      {((currentCategories.length !== 0 && !isFavoriteSection) ||
        (isFavoriteSection && hasFavorite)) && (
        <S.AllEventsLink
          onClick={(): void => onAllEventsLink({
                        isFavoriteSection,
                        currentCategories,
                        favoritesCategories,
                        isHighlights,
                        isLongTerm,
                        selectedSportCategory,
                        timeFilter
                    })}
          // prettier-ignore
        >
          {t('common.labels.all_games')}
        </S.AllEventsLink>
      )}
    </S.Title>
  );
};

export default CategoriesListTitle;
