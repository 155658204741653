import React from 'react';
import { useLocation } from 'react-router-dom';

import { PageName } from '@features/core/routing/linkAliases';

import { isMatchWebRoutes } from '@common/providers/router/helper';
import getImageBasePath from '@common/helpers/getImageBasePath';
import getHeaderText from '@common/helpers/userHelper/getAccHeader';
import { useRouterState } from '@common/providers/router/useRouterState';

import * as S from './AccountHeader.styled';

const AccountHeader: React.FC = () => {
  const location = useLocation();
  const isBets = isMatchWebRoutes(PageName.USER_BETS);
  const isChangePassword = isMatchWebRoutes(PageName.USER_CHANGE_PASSWORD);
  const isQRCodePage = isMatchWebRoutes(PageName.USER_PAYIN_QR);
  const currentRoute = useRouterState(s => s.currentWebRoute?.route);
  const headerText = getHeaderText(currentRoute);

  const getHeaderImage = (): React.ReactElement => {
    let src = '';
    let alt = '';
    if (isQRCodePage) {
      src = '/images/payment-aircash.svg';
      alt = 'aircash-logo';
    }
    if (src.length > 0) {
      return (
        <S.ImageWrapper>
          <S.ImageStyled
            src={getImageBasePath(src)}
            alt={alt}
            height="100%"
            width="50%"
          />
        </S.ImageWrapper>
      );
    }
    return <></>;
  };

  return headerText || getHeaderImage() ? (
    <S.Wrapper
      isBets={isBets && !/\d/.test(location.pathname)}
      isChangePassword={isChangePassword}
      isQRcode={isQRCodePage}
      className="accountHeader"
    >
      <S.HighlightBoldHeaderLabel>{headerText}</S.HighlightBoldHeaderLabel>
      {getHeaderImage()}
    </S.Wrapper>
  ) : null;
};

export default AccountHeader;
