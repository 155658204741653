/* eslint-disable no-console */
import isFunction from 'lodash/isFunction';

import services from '@features/core/services';

import { StaticCalls } from '@common/api/api';

export const logger = async (error): Promise<void> => {
  try {
    await services.http.post(StaticCalls.API_LOGS, {
      time: new Date().toString(),
      type: error.type,
      message: error.message || error.reason.message,
      module: `${error.filename} : ${error.lineno}`,
      stack: `${error?.error?.stack || error?.reason?.stack}`,
      location: window?.location?.href,
    });
  } catch (e) {
    services.logger.log(`Not able to post logs to server ${e}`);
  }
};

export const initialize = (): void => {
  window.addEventListener('error', (event): void => {
    console.error(`${event.filename} : ${event.lineno}`);
    logger(event);
  });

  window.addEventListener('rejectionhandled', (event): void => {
    event.preventDefault();
    if (event.reason.stack) {
      if (isFunction(console.group)) {
        console.group('HANDLED PROMISE REJECTION');
      }
      console.error(event.reason);
      console.error(event.promise);
      if (isFunction(console.groupEnd)) {
        console.groupEnd();
      }
      logger(event);
    }
  });

  window.addEventListener('unhandledrejection', (event): void => {
    event.preventDefault();
    if (event.reason.stack) {
      if (isFunction(console.group)) {
        console.group('UNHANDLED PROMISE REJECTION');
      }
      console.error(event.reason);
      console.error(event.promise);
      if (isFunction(console.groupEnd)) {
        console.groupEnd();
      }
      logger(event);
    }
  });
};

export default initialize;
