import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { Button } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

export const SelfLockContainer = styled.div<{ isDefaultMessage: boolean }>`
  display: flex;
  ${({ theme, isDefaultMessage }) =>
    !theme.isDesktop && isDefaultMessage
      ? 'flex-direction: row; justify-content: center; align-items: center;'
      : 'flex-direction: column;'};
  margin-bottom: ${({ theme }) =>
    theme.isDesktop ? theme.space_10 : theme.space_5};
  background: ${({ theme }) => theme.textMain};

  & .notification-text-string {
    line-height: 1.43em; /* stylelint-disable-line plugin/line-height-disallow */
    margin-top: ${({ theme, isDefaultMessage }) =>
      isDefaultMessage ? 0 : theme.space_10};
    margin-bottom: ${({ theme, isDefaultMessage }) =>
      isDefaultMessage ? 0 : theme.space_10};
    font-size: ${({ theme, isDefaultMessage }) =>
      theme.isDesktop || !isDefaultMessage ? theme.fontMD : theme.fontSM};
  }

  & > svg {
    width: ${props => props.theme.space_20};
    height: ${props => props.theme.space_20};
  }
`;

export const SelfLockWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  padding: ${({ theme }) => theme.space_10};
`;

export const SelfLockText = styled(TextNormal)<{ isError: boolean }>`
  color: ${({ theme }) => theme.main};
  font-size: ${props => props.theme.fontMD};
  margin-top: ${({ theme }) => theme.space_10};
`;

export const ExclusionButton = styled(Button)<{ isError: boolean }>`
  margin: ${props => props.theme.space_10};
  margin-top: ${({ isError, theme }) => (isError ? 0 : theme.space_10)};
  max-width: ${({ theme, isError }) =>
    !theme.isDesktop && !isError ? '180px' : '340px'};
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  order: ${({ theme }) => (theme.isDesktop ? '0' : '-1')};
`;

export const LoaderIcon = styled(Icon)`
  animation: spinner 1s linear infinite;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;
