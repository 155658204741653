import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated as a } from '@react-spring/web';
import { useLocation, useParams } from 'react-router-dom';

import { ILoctationState } from '@pages/cashout/Cashout.types';

import { PageName } from '@features/core/routing/linkAliases';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import CashoutItemButtons from '@features/cashout/components/cashoutItemButtons/CashoutItemButtons';
import CashoutItemTitle from '@features/cashout/components/cashoutItemTitle/CashoutItemTitle';
import CashoutItemDetail from '@features/cashout/components/cashoutItemDetail/CashoutItemDetail';
import CashoutPinIcon from '@features/cashout/components/cashoutPinIcon/CashoutPinIcon';

import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import deleteTimeout from '@common/helpers/timeoutHelper';
import { ButtonType, TTimeout } from '@common/interfaces';
import { setLocation } from '@common/providers/router/helper';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_QUEUED,
} from '@common/providers/cashout/state';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { setSelectedBetId } from '@common/providers/bets/useBets';
import { removeRequestedCashout } from '@common/providers/cashout/useCashout';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './CashoutItem.styled';
import { ICashoutItem, StatusBet } from './CashoutItem.types';

const CashoutItem: React.FC<ICashoutItem> = ({ betId, even }, ref) => {
  const { requestedCashout } = useCashoutItemModel(betId);
  const location = useLocation();
  const loctationState: ILoctationState = location.state as ILoctationState;
  const { id: idCashoutParam }: { id: string } = useParams();
  const state = requestedCashout && requestedCashout.state;
  const [stateStatus, setStateStatus] = useState<StatusBet>(StatusBet.DEFAULT);
  const [extended, setExtended] = useState<boolean>(false);
  const { t } = useTranslation();
  const cashoutItemRef = useRef<HTMLDivElement>(null);
  const timeoutScrollRef = useRef<TTimeout>(null);
  const timeoutExtendDetailRef = useRef<TTimeout>(null);
  const isDesktop = isDesktopView();
  const opened =
    betId === loctationState?.cashoutId || betId === idCashoutParam;

  const animationProps = {
    from: { maxHeight: 50 },
    maxHeight: extended ? '100%' : 50,
  };

  const scrollToElem = (): void => {
    if (cashoutItemRef.current) {
      elementScrollIntoView(cashoutItemRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }

    if (cashoutItemRef.current && !isDesktop) {
      elementScrollIntoView(cashoutItemRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  useEffect(() => {
    if (opened) {
      setExtended(true);
      timeoutScrollRef.current = setTimeout(scrollToElem, 100);
    }

    return () => {
      deleteTimeout(timeoutScrollRef.current, timeoutExtendDetailRef.current);
    };
  }, []);

  useEffect(() => {
    if (state === STATE_ACCEPTED) {
      setExtended(false);
      setStateStatus(StatusBet.DEFAULT);
    } else if (state === STATE_ERROR) {
      setStateStatus(StatusBet.DISABLED);
    }
  }, [requestedCashout]);

  const extendDetail = (): void => {
    timeoutExtendDetailRef.current = setTimeout(() => {
      setExtended(!extended);
      setStateStatus(StatusBet.DEFAULT);
      if (state === STATE_ERROR) {
        removeRequestedCashout(betId);
      }

      timeoutScrollRef.current = setTimeout(scrollToElem, 500);
    }, 200);
  };

  const goToBetDetails = (): void => {
    if (isDesktop) {
      setSelectedBetId(betId);
    } else {
      setLocation(PageName.USER_BET_ITEM, { id: betId });
    }
  };

  return (
    <div ref={ref}>
      <S.Wrapper ref={cashoutItemRef} data-qa="cashout-item">
        <a.div
          style={{ ...animationProps }}
          className={isDesktop ? '' : 'slide-inner'}
        >
          <div className={`short-view${extended ? ' open' : ''}`}>
            <CashoutPinIcon betId={betId} />

            <CashoutItemTitle
              betId={betId}
              extended={extended}
              extendDetail={extendDetail}
            />

            {extended && (
              <StyledButton
                onClick={goToBetDetails}
                dataQa="cashout-btn-toDetails"
                label="cashout.go_to_details"
                className="go-to-details-btn"
                buttonType={ButtonType.LightGhost}
              />
            )}

            <S.OpenButton
              open={extended}
              disabled={
                stateStatus === StatusBet.DISABLED ||
                state === STATE_ACCEPTED ||
                state === STATE_QUEUED
              }
              className="shevron-wrapper"
              onClick={extendDetail}
              data-qa="cashout-btn-toggleItem"
              aria-label={
                !extended
                  ? t('common.buttons.open_bet_detail')
                  : t('common.buttons.close_bet_detail')
              }
            >
              <Icon name="chevron-down" width="10" height="10" />
            </S.OpenButton>
          </div>

          <CashoutItemDetail
            betId={betId}
            even={even}
            setStateStatus={setStateStatus}
            extended={extended}
          />
        </a.div>

        <CashoutItemButtons
          betId={betId}
          extended={extended}
          stateStatus={stateStatus}
          setStateStatus={setStateStatus}
          extendDetail={extendDetail}
          scrollToElem={scrollToElem}
        />
      </S.Wrapper>
    </div>
  );
};

export { CashoutItem as Component };

// forwardRef used for FlipMove
export default forwardRef(
  CashoutItem as React.ForwardRefRenderFunction<ICashoutItem, ICashoutItem>,
);
