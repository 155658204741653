import React from 'react';

import { StatusBet } from '@features/cashout/components/cashoutItem/CashoutItem.types';
import { Selections } from '@features/events/components/selections';
import { BetStats } from '@features/cashout/components/betStats';
import { Rejected } from '@features/cashout/components/states/rejected';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import { ICashoutItemDetail } from '@features/cashout/components/cashoutItemDetail/CashoutItemDetail.types';

import { STATE_ERROR } from '@common/providers/cashout/state';
import { removeRequestedCashout } from '@common/providers/cashout/useCashout';
import { isDesktopView } from '@common/helpers/deviceUtil';

const CashoutItemDetail: React.FC<ICashoutItemDetail> = ({
  betId,
  even,
  setStateStatus,
  extended,
}) => {
  const {
    requestedCashout,
    totalStake,
    unavailableSelectionId,
    selections,
    remainingRisk,
  } = useCashoutItemModel(betId);
  const state = requestedCashout && requestedCashout.state;
  const isDesktop = isDesktopView();
  const isDetailVisible = (isDesktop && extended) || !isDesktop;

  if (!isDetailVisible) {
    return null;
  }

  return (
    <div className="extended-view">
      <Selections
        type="cashout"
        even={even}
        betId={betId}
        selections={selections}
        unavailableSelectionId={unavailableSelectionId}
      />

      <BetStats
        betId={betId}
        totalStake={totalStake}
        remainingRisk={remainingRisk}
      />

      {state === STATE_ERROR && (
        <Rejected
          onClose={(): void => {
            setStateStatus(StatusBet.DEFAULT);
            removeRequestedCashout(betId);
          }}
          message={requestedCashout?.error?.message}
        />
      )}
    </div>
  );
};

export default CashoutItemDetail;
