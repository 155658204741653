import React, { useState } from 'react';

import { StatusBet } from '@features/cashout/components/cashoutItem/CashoutItem.types';
import { CashoutButton } from '@features/cashout/components/cashoutButton';
import { CashoutInputs } from '@features/cashout/components/cashoutInputs';
import { Accepted } from '@features/cashout/components/states/accepted';
import { Rejected } from '@features/cashout/components/states/rejected';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import { ICashoutItemButtons } from '@features/cashout/components/cashoutItemButtons/CashoutItemButtons.types';

import { removeRequestedCashout } from '@common/providers/cashout/useCashout';
import { STATE_ACCEPTED, STATE_ERROR } from '@common/providers/cashout/state';

const CashoutItemButtons: React.FC<ICashoutItemButtons> = ({
  betId,
  extended,
  stateStatus,
  setStateStatus,
  extendDetail,
  scrollToElem,
}) => {
  const { requestedCashout } = useCashoutItemModel(betId);
  const state = requestedCashout && requestedCashout.state;
  const [partCashout, setPartCashout] = useState<boolean>(false);

  return (
    <>
      {!extended && state !== STATE_ACCEPTED && (
        <CashoutButton
          betId={betId}
          stateStatus={stateStatus}
          partCashout={partCashout}
          setStateStatus={setStateStatus}
          setPartCashout={setPartCashout}
        />
      )}

      {extended && (
        <CashoutInputs
          betId={betId}
          closeExtended={extendDetail}
          scrollToElem={scrollToElem}
          setStateStatus={setStateStatus}
          setPartCashout={setPartCashout}
        />
      )}

      {state === STATE_ACCEPTED && (
        <Accepted
          partCashout={partCashout}
          paidStatus={requestedCashout?.paid_status}
          callBack={(): void => {
            removeRequestedCashout(betId);
          }}
        />
      )}

      {state === STATE_ERROR && !extended && (
        <Rejected
          onClose={(): void => {
            setStateStatus(StatusBet.DEFAULT);
            removeRequestedCashout(betId);
          }}
          message={requestedCashout?.error?.message}
        />
      )}
    </>
  );
};

export default CashoutItemButtons;
