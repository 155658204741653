import styled from 'styled-components';

export const AmountWrapper = styled.div<{
  isPartCashout: boolean;
  isReadySubMax: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  justify-items: end;
  grid-gap: ${({ theme }) => theme.space_10};

  ${({ isReadySubMax }) => isReadySubMax && 'grid-template-columns: 1fr;'};
  ${({ isPartCashout }) => isPartCashout && 'grid-auto-columns: 1fr auto;'};

  button {
    padding: ${({ theme }) => theme.space_10};
    grid-template-columns: 1fr;
  }

  .input {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    width: 55%;
  }

  .virtual_input {
    div {
      border-color: transparent transparent ${({ theme }) => theme.secondary}
        transparent;
    }
  }
`;

export const WrapKeyboard = styled.div`
  margin: ${({ theme }) => theme.space_10} -10px -5px;

  div::before,
  div::after {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

export const PartCashoutContainer = styled.div<{ extended?: boolean }>`
  padding: ${({ theme }) => theme.space_15} 0 0;
  ${props => !props.extended && 'display: none'};
`;
