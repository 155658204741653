import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';
import { headerWithGenericScore } from '@features/detail/components/detailEvent/DetailEvent.styled';

export const LeftSection = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  padding: 0 0 ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_10};
`;
export const CenterSection = styled.div`
  text-align: center;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;

  .score {
    padding: 0;
  }

  span {
    max-width: 70px;
    text-align: center;
  }
`;

export const RightSection = styled.div`
  display: grid;
  justify-content: end;
  padding: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15} 0;

  > div {
    justify-content: start;
  }
`;

export const RightSectionCell = styled.div`
  display: grid;
  grid-template-columns: auto;
  text-align: right;
`;

export const Title = styled(TextNormal)`
  font-weight: ${({ theme }) => theme.fontBold};
  ${({ theme }) => !theme.isDesktop && 'max-width: 110px'};

  text-align: start;
`;

export const EventHeader = styled.div<{ hasGenericScore?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.textMain};
  padding: ${({ theme }) => theme.space_10} 0;

  margin: 0;
  ${({ hasGenericScore }) => hasGenericScore && headerWithGenericScore};
`;

export const ScoreboardWrapper = styled.div`
  margin-left: auto;
  padding-right: ${({ theme }) => theme.space_10};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: ${({ theme }) => theme.space_5};

  span {
    max-width: max-content;
  }
`;
