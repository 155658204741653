import React from 'react';
import replace from 'lodash/replace';
import { useTranslation } from 'react-i18next';

import { Inputs } from '@features/cashout/components/stateInputs';
import CountdownDoubleWhiteButton from '@features/bettingslip/components/buttons/CountdownDoubleWhiteButton';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import { ICashoutMaxButton } from '@features/cashout/components/cashoutMaxButton/CashoutMaxButton.types';

import { STATE_ERROR, STATE_QUEUED } from '@common/providers/cashout/state';
import { formatCashoutOffer } from '@common/helpers/cashoutHelper';
import { useCashoutState } from '@common/providers/cashout/useCashout';

import { StyledButton } from '@ui/components/buttons';
import { ButtonSize, ButtonType } from '@ui/components/buttons/Button.styled';

import * as S from './CashoutMaxButton.styled';

const CashoutMaxButton: React.FC<ICashoutMaxButton> = ({
  betId,
  readySubmitPart,
  readySubmitMax,
  countdown,
  partValue,
  setReadySubmitMax,
  setReadySubmitPart,
  submit,
  newCashoutOffer,
}) => {
  const loading = useCashoutState(s => s.loading);
  const { requestedCashout, cashoutDisabled, totalStake } = useCashoutItemModel(
    betId,
  );
  const { t } = useTranslation();
  const state = requestedCashout && requestedCashout.state;
  const isQueued = state === STATE_QUEUED;

  const onReadySubmitMax = (): void => {
    setReadySubmitMax(true);
    setReadySubmitPart(false);
  };

  return (
    <S.AmountButtonWrapper
      aria-disabled={readySubmitPart && !readySubmitMax && isQueued}
      noGap={!readySubmitMax && !isQueued && !loading && state !== STATE_ERROR}
    >
      {readySubmitMax && !isQueued && !loading && state !== STATE_ERROR && (
        <>
          <StyledButton
            className="max_cashout"
            disabled={loading}
            onClick={(): void => submit(true, totalStake)}
            aria-label={t('aria_labels.buttons.submit')}
            label={formatCashoutOffer(newCashoutOffer, cashoutDisabled, true)}
            size={ButtonSize.Big}
          />
          <StyledButton
            onClick={(): void => setReadySubmitMax(false)}
            aria-label={t('aria_labels.buttons.close')}
            label=""
            icon={{
              name: 'close',
              width: 10,
              height: 10,
              className: 'icon-close',
              isSingle: true,
            }}
            buttonType={ButtonType.LightGhost}
            size={ButtonSize.Big}
          />
        </>
      )}
      {!readySubmitMax && !isQueued && !loading && state !== STATE_ERROR && (
        <Inputs
          value={String(newCashoutOffer)}
          disabledBtn={readySubmitPart || cashoutDisabled}
          onReadySubmit={onReadySubmitMax}
          inputType="max"
          isLock={cashoutDisabled}
        />
      )}

      {(readySubmitPart || readySubmitMax) && isQueued && (
        <CountdownDoubleWhiteButton
          label={
            readySubmitPart
              ? formatCashoutOffer(
                  replace(partValue, ',', '.'),
                  cashoutDisabled,
                )
              : formatCashoutOffer(newCashoutOffer, cashoutDisabled)
          }
          countdown={countdown}
          isWhite
          className={readySubmitPart ? 'part-queued' : 'max_cashout'}
          areaLabel={
            readySubmitPart
              ? t('aria_labels.buttons.submit')
              : t('aria_labels.buttons.submit_cashout')
          }
        />
      )}

      {(loading || state === STATE_ERROR) && !isQueued && !readySubmitPart && (
        <CountdownDoubleWhiteButton
          label={formatCashoutOffer(newCashoutOffer, cashoutDisabled)}
          className="disabled_cashout"
          disabled
          areaLabel={t('aria_labels.buttons.disable_cashout')}
          isWhite
          areaLabelCountdown={t('aria_labels.buttons.go_queued')}
          isLockIcon
        />
      )}
    </S.AmountButtonWrapper>
  );
};

export default CashoutMaxButton;
