import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import head from 'lodash/head';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import replace from 'lodash/replace';
import split from 'lodash/split';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import padStart from 'lodash/padStart';
import assign from 'lodash/assign';
import map from 'lodash/map';
import every from 'lodash/every';
import find from 'lodash/find';

import services from '@features/core/services';
import i18next from '@features/core/translation';
import countries from '@features/core/countriesList';

import { COLLAPSED_CATS } from '@common/constants/config';
import { ICategory, ILiveSportCategoriesTreeData } from '@common/interfaces';
import categoriesKeys, {
  categoriesToExclude,
  ExpandableWithTable,
  namedCategories,
  SPORT_WEIGHTS,
} from '@common/helpers/categories/categories';
import {
  EventTimeFilters,
  IChildNParentIds,
  IdParentMap,
} from '@common/interfaces/events/ILiveCategories';

/**
 * getSportName
 *
 * @param {string} id
 * @returns {string} sportName
 */
export const getSportName = (id: string): string => {
  if (namedCategories[id]) {
    return namedCategories[id];
  }
  services.logger.error(`MISSING SPORT CATEGORY NAME: ${id}`);
  return 'football'; // default sport name for the icon
};

/**
 * getIdsPathToCategory
 *
 * @param {ILiveSportCategoriesTreeData[]} tree
 * @param {string} id
 * @returns {string[]} idsPath
 */
export const getIdsPathToCategory = (
  tree: ILiveSportCategoriesTreeData[],
  id: string,
): string[] => {
  return reduce(
    tree,
    (path: string[], item) => {
      if (item.id === id) {
        path.push(id);
      } else if (item.children) {
        const childrenResult = getIdsPathToCategory(item.children, id);

        if (childrenResult.length) {
          path.push(item.id, ...childrenResult);
        }
      }
      return path;
    },
    [],
  );
};

export const checkAllIdsSameParent = (
  idsToCheck: string[],
  categoriesTree: ILiveSportCategoriesTreeData[],
): boolean => {
  return every(idsToCheck, id => {
    return !!find(
      getIdsPathToCategory(categoriesTree, id),
      idFromPath => ExpandableWithTable[idFromPath],
    );
  });
};

/**
 * createIdParentMap
 *
 * @param {ILiveSportCategoriesTreeData[]} data
 * @returns {IdParentMap} parentMap
 */
export const createIdParentMap = (
  data: ILiveSportCategoriesTreeData[],
): IdParentMap => {
  return reduce(
    data,
    (parentMap, item) => {
      const { id, parent_id: parentId, children } = item;
      // eslint-disable-next-line no-param-reassign
      parentMap[id] = { parent_id: parentId };
      if (children && children.length > 0) {
        assign(parentMap, createIdParentMap(children));
      }
      return parentMap;
    },
    {},
  );
};

const findParentsRecursively = (
  id,
  idParentMap,
  parentIds = [] as string[],
): string[] => {
  const parentId = idParentMap[id]?.parent_id;
  if (parentId !== '0' && parentId !== undefined) {
    parentIds.push(parentId);
    return findParentsRecursively(parentId, idParentMap, parentIds);
  }
  return parentIds;
};

const findParentIdsForIds = (
  idList: string[],
  idParentMap: IdParentMap | object,
): IChildNParentIds[] => {
  return map(idList, id => {
    const parentIds = findParentsRecursively(id, idParentMap);
    return { incomeId: id, parentIds };
  });
};

/**
 * checkChildrenOfParent
 *
 * @param {string | undefined} id
 * @param {Record<string, string>} idList // favoritesIds
 * @param {IdParentMap | object} idParentMap
 * @returns {string[]} catChildrenExist
 */
export const checkChildrenOfParent = (id, idList, idParentMap): string[] => {
  const parentIds = findParentIdsForIds(keys(idList), idParentMap);
  const matchingItems = filter(parentIds, item => includes(item.parentIds, id));
  return map(matchingItems, 'incomeId');
};

/**
 * getCategoryById
 *
 * @param {ILiveSportCategoriesTreeData[]} tree
 * @param {string} id
 * @returns {ICategory} category
 */
export const getCategoryById = (
  tree: ILiveSportCategoriesTreeData[],
  id: string,
): ICategory => {
  let result;

  forEach(tree, item => {
    if (item?.id === id) {
      result = item;
    }
    if (item.children) {
      const childrenResult = getCategoryById(item.children, id);
      if (childrenResult) {
        result = childrenResult;
      }
    }
  });

  return result;
};

/**
 * getSportWeightString
 * returns sport weight string for category
 *
 * @param {ICategory} category
 * @returns {string} sportWeight
 */
export const getSportWeightString = (category: ICategory): string => {
  const sport_weight = SPORT_WEIGHTS[category?.top_category_id]
    ? SPORT_WEIGHTS[category?.top_category_id]
    : 999;
  return padStart(sport_weight, 3, '0');
};

/**
 * getPaddedId
 * returns padded category id for coma
 *
 * @param {string} id
 * @param {number} lgth
 * @returns {string} category_id
 */
export const getPaddedId = (id: string, lgth?: number): string => {
  return padStart(id, lgth || 6, '0');
};

/**
 * getPath
 * returns category path string
 *
 * @param {ICategory | ILiveSportCategoriesTreeData} category
 * @returns {string} path
 */
export const getPath = (category: ICategory): string => {
  return (category && category.path) || '';
};

/**
 * getTopID
 * returns id of top category
 *
 * @param {ICategory} category
 * @returns {string} topId
 */
export const getTopID = (category: ICategory): string => {
  return (category && category.top_category_id) || '';
};

/**
 * getLocks
 * returns category locks
 *
 * @param {ICategory} category
 * @returns {number[]} locks
 */
export const getLocks = (category?: ICategory): number[] => {
  return category?.locks || [];
};

/**
 * excludeCategoriesTreeFromFilter
 * removes duplicates from filters
 *
 * @param {ILiveSportCategoriesTreeData[]} categories
 * @returns {ILiveSportCategoriesTreeData[]} data
 */
export const excludeCategoriesTreeFromFilter = (
  categories: ILiveSportCategoriesTreeData[],
): ILiveSportCategoriesTreeData[] => {
  return filter(categories, item => !categoriesToExclude[item.id]);
};

/**
 * extendRequestCategories
 * add additional categories for fetch after changing filters
 *
 * @param {string[]} categories
 * @returns {string[]} extendedCategories
 */
export const extendRequestCategories = (categories: string[]): string[] => {
  return reduce(
    categories,
    (acc: string[], category) => {
      if (keys(categoriesKeys.football)[0] === category) {
        return acc.concat([
          ...keys(categoriesKeys.football),
          ...keys(categoriesKeys.minisoccer),
          ...keys(categoriesKeys.beachsoccer),
        ]);
      }
      if (categoriesKeys.tennis[category]) {
        return acc.concat([...keys(categoriesKeys.tennis)]);
      }
      if (categoriesKeys.handball[category]) {
        return acc.concat([...keys(categoriesKeys.handball)]);
      }
      acc.push(category);
      return acc;
    },
    [],
  );
};

/**
 * addCategoryAbbreviations
 *
 * @param {string} title
 * @param {string} lang
 * @returns {string} sportName
 */
export const addCategoryAbbreviations = (
  title: string,
  lang?: string,
): string => {
  let val = title || '';
  const language = lang || services.domainLang;
  if (includes(val, '/')) {
    val = replace(val, 'Internationale Vereinsspiele', 'Int. Vereinsspiele');
    val = replace(val, 'International Junioren', 'Int. Junioren');
    val = replace(val, 'International Clubs', 'Int. Clubs');
    val = replace(val, 'International Youth', 'Int. Youth');
    val = replace(
      val,
      /(\w+)( International)/,
      `$1 ${i18next.t('helpers.international')}`,
    );
  }

  const POSSIBLE_NEUTRAL_VENUE = '(poss. neutral venue)';

  if (language === 'de') {
    val = replace(val, 'inklusive Verlängerung', '');
    val = replace(val, 'inkl. Verlängerung', '');
    val = replace(val, '(neutr. Platz mögl.)', '');
    val = replace(val, 'American', '');
  } else if (language === 'es') {
    val = replace(val, POSSIBLE_NEUTRAL_VENUE, '');
    val = replace(val, 'incl. tiempo extra', '');
    val = replace(val, 'americano', '');
  } else if (language === 'fr') {
    val = replace(val, POSSIBLE_NEUTRAL_VENUE, '');
    val = replace(val, 'temps additionnel compris', '');
    val = replace(val, 'américain', '');
  } else {
    val = replace(val, 'incl. Overtime', '');
    val = replace(val, POSSIBLE_NEUTRAL_VENUE, '');
    val = replace(val, 'American', '');
    val = replace(val, 'Αμερικάνικο ποδόσφαιρο', 'Ποδόσφαιρο');
    val = replace(val, 'Американский футбол', 'Футбол');
    val = replace(val, 'Amerikan futbolu', 'Futbol');
  }
  if (includes(val, '---')) {
    val = head(split(val, '---')) || '';
  }
  return replace(trim(val, ' /'), /\/\s+\//g, '/');
};

export const collapsedCategoriesId = services.config.get(COLLAPSED_CATS);

/**
 * processChildren
 *
 * @param {ILiveSportCategoriesTreeData[] | null | undefined} children
 * @param {number} level
 * @returns {ConcatArray<never>} categories
 */
export const processChildren = (
  children,
  level: number,
): ConcatArray<never> => {
  return reduce(
    children,
    (acc, e) => {
      if (!e.count) {
        return acc;
      } // exclude categories without events
      if (
        e.children?.length &&
        parseFloat(e.level) <= level &&
        !collapsedCategoriesId[e.id]
      ) {
        return acc.concat(processChildren(e.children, level));
      }
      return acc.concat(e);
    },
    [],
  );
};

/**
 * getAllChildren
 *
 * @param {ILiveSportCategoriesTreeData[] | null | undefined} children
 * @returns {ILiveSportCategoriesTreeData[] | []} children
 */
export const getAllChildren = (
  children,
): ILiveSportCategoriesTreeData[] | [] => {
  return reduce(
    children,
    (acc: ILiveSportCategoriesTreeData[], e) => {
      if (e.children?.length) {
        return acc.concat(getAllChildren(e.children));
      }
      return acc.concat(e);
    },
    [],
  );
};

/**
 * getAllChildrenIds
 *
 * @param {ILiveSportCategoriesTreeData} item
 * @returns {string[]} childrenCategories
 */
export const getAllChildrenIds = (
  item: ILiveSportCategoriesTreeData,
): string[] => {
  return reduce(
    getAllChildren(item.children),
    (acc: string[], el) => {
      acc.push(el.id);

      return acc;
    },
    [] as string[],
  );
};

/**
 * filterCategoriesTree
 *
 * @param {ILiveSportCategoriesTreeData[]} categoriesTree
 * @param {Record<number, number>} ids
 * @returns {ILiveSportCategoriesTreeData[]} categories
 */
export const filterCategoriesTree = (
  categoriesTree: ILiveSportCategoriesTreeData[],
  ids: Record<number, number>,
): ILiveSportCategoriesTreeData[] => {
  const result: ILiveSportCategoriesTreeData[] = [];

  const iterate = (items: ILiveSportCategoriesTreeData[]): void => {
    forEach(items, item => {
      if (ids[item.id] && item.count) {
        result.push(
          assign(item, { children: processChildren(item.children, 3) }),
        );
      }
      if (!ids[item.id] && item.children && item.count) {
        iterate(item.children);
      }
    });
  };
  iterate(categoriesTree);
  return result;
};

export const deUmlaut = (value: string): string => {
  let parsed = toLower(value);
  parsed = replace(parsed, /ä/g, 'ae');
  parsed = replace(parsed, /ö/g, 'oe');
  parsed = replace(parsed, /ü/g, 'ue');
  parsed = replace(parsed, /ß/g, 'ss');
  parsed = replace(parsed, / /g, '-');
  parsed = replace(parsed, /\./g, '');
  parsed = replace(parsed, /,/g, '');
  parsed = replace(parsed, /\(/g, '');
  parsed = replace(parsed, /\)/g, '');
  return parsed;
};

export const deUmlautNormal = (value: string): string => {
  let parsed = value;
  parsed = replace(parsed, /ä/g, 'ae');
  parsed = replace(parsed, /ö/g, 'oe');
  parsed = replace(parsed, /ü/g, 'ue');
  parsed = replace(parsed, /ß/g, 'ss');
  parsed = replace(parsed, /\./g, '');
  parsed = replace(parsed, /,/g, '');
  parsed = replace(parsed, /\(/g, '');
  parsed = replace(parsed, /\)/g, '');
  return parsed;
};

export const isLongTime = (label: string): boolean => {
  return includes(label, ' - ');
};

/**
 * getCategoryLabelPrefix
 * gets category label prefix
 *
 * @param {string} id
 * @returns {string | null} prefix
 */
export const getCategoryLabelPrefix = (id: string): string | null => {
  const categoryPrefixList: Record<string, string> = {
    8841: 'CL ',
    8842: 'EL ',
    27257: 'ConL ',
  };
  return categoryPrefixList[id] || null;
};

export const getItemFilterQuery = (queryFilter: string): string => {
  switch (queryFilter) {
    case '':
      return 'all';
    case 'today':
      return 'today';
    case '24h':
      return 'next24hrs';
    case '3h':
      return 'next3hrs';
    default:
      return 'all';
  }
};

export const getTimeSwitcherText = (filterValue: string): string => {
  switch (filterValue) {
    case '':
      return i18next.t('events.filters.all');
    case 'today':
      return i18next.t('events.filters.today');
    case '24h':
      return i18next.t('events.filters.24h_short');
    case '3h':
      return i18next.t('events.filters.3h_short');
    default:
      return '';
  }
};

export const getFilter = (
  pathName: string,
): keyof typeof EventTimeFilters | '' => {
  let result: keyof typeof EventTimeFilters | '' = '';
  if (includes(pathName, '/category') && includes(pathName, '/today')) {
    result = 'today';
  } else if (
    includes(pathName, '/category') &&
    includes(pathName, '/next24hrs')
  ) {
    result = '24h';
  } else if (
    includes(pathName, '/category') &&
    includes(pathName, '/next3hrs')
  ) {
    result = '3h';
  }
  return result;
};

export const isOlympiaGame = (path: string | undefined): boolean =>
  /Olympia|Olympic/.test(path || '');

export const isFlagImageJpeg = (countryName: string): boolean =>
  !!countries[countryName] || includes(countryName, 'UEFA');
