import React, { useMemo } from 'react';

import { IWalletView } from '@features/wallets/walletSwitcher';

import { WALLET_SWITCHER } from '@common/constants/dialogs';
import { IUser } from '@common/interfaces';
import {
  toggleSidebar,
  useAppState,
} from '@common/providers/application/useAppState';
import { setWalletView } from '@common/providers/payments/wallets/useWallets';
import { isOpenDialog } from '@common/helpers/appState/dialogs';

import * as S from './UserInfo.styled';

interface IUserInfoProps {
  user: IUser | null;
  isSingleWallet: boolean;
}

const UserInfo: React.FC<IUserInfoProps> = ({ user, isSingleWallet }) => {
  const openedSidebars = useAppState(s => s.openedSidebars);
  const isWalletSwitcherOpened = useMemo(() => isOpenDialog(WALLET_SWITCHER), [
    openedSidebars,
  ]);
  const handleOpenWalletSwitcher = (e): void => {
    e.stopPropagation();
    toggleSidebar(WALLET_SWITCHER);
    setWalletView(IWalletView.SMALL_VIEW);
  };

  return (
    <S.WalletUserInfo
      onClick={handleOpenWalletSwitcher}
      data-qa="header-user-info"
    >
      <S.WalletInfoIcon
        className="wallet-info-icon"
        width="20"
        height="20"
        name={user?.type === 'www' ? 'global' : 'credit-card'}
      />
      <S.StyledBalancedBadge />
      <S.SwitcherWrapper
        isSwitcherOpened={isWalletSwitcherOpened && isSingleWallet}
      >
        <S.SwitcherIcon
          name={isSingleWallet ? 'chevron-large-down' : 'change-wallet'}
          width="12"
          height="12"
        />
      </S.SwitcherWrapper>
    </S.WalletUserInfo>
  );
};

export default UserInfo;
