import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import toUpper from 'lodash/toUpper';

import { useServices } from '@features/core/services';
import { LugasTimer } from '@features/user/components/lugasTimer';
import { PageName } from '@features/core/routing';

import { REALITY_LAYER } from '@common/constants/dialogs';
import { REALITY_TIMER } from '@common/constants/cookie';
import { ButtonSize, ButtonType, SidebarSide } from '@common/interfaces';
import logoutUser from '@common/providers/user/actions/logoutUser';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { MethodType } from '@common/api/apiHandler';
import { setLocation } from '@common/providers/router/helper';
import { closeSidebar } from '@common/providers/application/useAppState';
import { usePaymentsState } from '@common/providers/payments/usePayments';
import { realityPeriod } from '@common/hooks/useLugasActiveTimer';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './RealityCheck.styled';

const handlerClick = (e): void => {
  if (
    !(
      e.target.classList.contains('reality-button') ||
      e.target.parentElement.classList.contains('reality-button')
    )
  ) {
    e.stopPropagation();
    e.preventDefault();
  }
};

const RealityCheck = (): React.ReactElement => {
  const body = document.querySelector('body');
  const { t } = useTranslation();
  const { cookie } = useServices();
  const account = usePaymentsState(s => s.account);

  useEffect(() => {
    body?.addEventListener('click', handlerClick, true);
    return () => body?.removeEventListener('click', handlerClick, true);
  }, []);

  useEffect(() => {
    if (account.token) {
      (async (): Promise<void> => {
        const { tag } = await fetchPocketTags({
          method: MethodType.Get,
          params: {
            tag: {
              key: 'usr.front.RealityCheckProvider',
            },
          },
        });
        if (!tag?.value || Date.now() - Number(tag?.value) >= realityPeriod) {
          await fetchPocketTags({
            method: MethodType.Post,
            params: {
              tag: {
                key: 'usr.front.RealityCheckProvider',
                value: Date.now(),
              },
            },
          });
        }
      })();
    }
  }, [account.token]);

  const handleClose = async (): Promise<void> => {
    const currentTime = String(Date.now());
    await fetchPocketTags({
      method: MethodType.Post,
      params: {
        tag: {
          key: 'usr.activity',
          value: 'on',
        },
      },
    });
    await fetchPocketTags({
      method: MethodType.Post,
      params: {
        tag: {
          key: 'usr.front.RealityCheckPlayer',
          value: currentTime,
        },
      },
    });
    closeSidebar(REALITY_LAYER);
    cookie.set(REALITY_TIMER, currentTime, { expires: null });
  };

  const handleLogout = async (): Promise<void> => {
    closeSidebar(REALITY_LAYER);
    await logoutUser();
    setLocation(PageName.HOME);
  };

  return (
    <S.Root className="reality-check">
      <S.HeaderWrapper>
        <Icon name="updated-info-circle" />
        <S.Header>{toUpper(t('user.reality.reality_title'))}</S.Header>
      </S.HeaderWrapper>
      <S.WarningMessage>
        <LugasTimer type="reality" />
        {t('user.reality.reality_description')}
      </S.WarningMessage>
      <StyledButton
        onClick={handleClose}
        label="user.reality.reality_button"
        buttonType={ButtonType.Secondary}
        size={ButtonSize.Big}
        className="reality-button"
      />
      <StyledButton
        onClick={handleLogout}
        label="app-layout.user-menu.logout"
        buttonType={ButtonType.DarkGhost}
        size={ButtonSize.Big}
        className="reality-button"
      />
    </S.Root>
  );
};

RealityCheck.meta = {
  side: SidebarSide.center,
  key: REALITY_LAYER,
};

export { RealityCheck as Component };

export default RealityCheck;
