import styled from 'styled-components';

import { TextXLarge } from '@features/core/styles/text.styled';

export const Title = styled(TextXLarge)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: ${props => props.theme.space_10};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.textMain};
  background-color: ${props => props.theme.textActive};
`;

export const AllEventsLink = styled.div`
  margin-left: auto;
  color: ${props => props.theme.textLink};
  font-weight: ${({ theme }) => theme.fontRegular};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${({ theme }) => !theme.isDesktop && theme.fontMD};
`;
