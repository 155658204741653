import React from 'react';

import { getCurrencyFromCode } from '@common/helpers/paymentsHelper/walletsHelper';

import { Icon } from '@ui/components/icon';

const CurrencyIcon: React.FC = () => {
  const currency = getCurrencyFromCode();

  if (currency === '€') {
    return <Icon name="euro" width="13" height="16" />;
  }

  return <span>{currency}</span>;
};

export default CurrencyIcon;
