import { UserLoadingState } from '@common/providers/user/actions/actionTypes';
import { useUserState } from '@common/providers/user/useUserState';
/**
 * isLoggedIn
 * checks if user is registered in shop;
 *
 * @returns {boolean} isLoggedIn
 */
export default (): boolean => {
  return useUserState.getState().loadingState > UserLoadingState.LOADING;
};

export const isFullyLoggedIn = (): boolean => {
  return useUserState.getState().loadingState === UserLoadingState.LOGGED_IN;
};

export const isLoginProccessing = (loadingState: UserLoadingState): boolean =>
  loadingState !== UserLoadingState.LOGGED_OUT &&
  loadingState !== UserLoadingState.LOGGED_IN;
