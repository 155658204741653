import styled from 'styled-components';

import { footerHeight } from '@features/app/layout/footer';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.textMain};
  margin-bottom: ${({ theme }) => theme.space_5};
  scroll-margin-bottom: ${({ theme }) =>
    theme.isDesktop ? 0 : footerHeight}px;

  .go-to-details-btn {
    align-self: center;
    font-size: ${props => props.theme.fontSM};
    max-width: 95px;
    height: 22px;
  }

  .cashout-container {
    display: grid;
    grid-auto-flow: column;
    padding-left: ${({ theme }) => theme.space_10};
    padding-right: ${({ theme }) => theme.space_10};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.tertiaryBackground};

    .cashout-reverse-bg {
      grid-auto-flow: row;
    }
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.textActive};

    .cashout-reverse-bg {
      grid-auto-flow: row;
    }
  }

  .short-view {
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.space_10};
    background: inherit;
    z-index: 5;
    margin-right: auto;
    border-bottom: ${({ theme }) => theme.border};
    font-size: ${props => props.theme.fontSM};

    &.open {
      position: sticky;
      top: 0;

      &:nth-child(odd) {
        background-color: ${({ theme }) => theme.tertiaryBackground};
      }

      &:nth-child(even) {
        background-color: ${({ theme }) => theme.textActive};
      }
    }
  }

  .cashout-selection {
    display: grid;
    justify-content: space-between;
  }

  .slide-inner {
    overflow: hidden;
    transition: max-height 0.4s;
    position: relative;
  }
`;

export const Text = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
`;

const getOpenButtonMobStyles = ({ theme }) => `
padding: ${theme.space_5} ${theme.space_10};
`;

export const OpenButton = styled.button<{ open: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  width: ${({ theme }) => (theme.isDesktop ? 25 : 32)}px;
  ${({ theme }) => !theme.isDesktop && getOpenButtonMobStyles}

  background-color: transparent;
  border: ${({ theme }) => theme.border};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  align-self: center;
  cursor: pointer;

  ${({ open }) => open && 'svg {transform: rotateX(180deg)}'};

  path,
  svg {
    fill: ${({ theme }) => theme.textMain};
  }
`;
