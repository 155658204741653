/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import { Socket, Channel } from 'phoenix';

import services from '@features/core/services';

import { BET_RM_SOCKET_URL } from '@common/constants/config';
import {
  setBetsSocketData,
  resetUpdates,
} from '@common/providers/bets/useBets';

interface UseBetDetailSocketProps {
  betId: string;
  barcode?: string;
  isScannerResultPage: boolean;
  userId: string;
  betUserId: string;
}

export const useBetDetailSocket = ({
  betId,
  barcode,
  isScannerResultPage,
  userId,
  betUserId,
}: UseBetDetailSocketProps): void => {
  const socketRef = useRef<Socket | null>(null);
  const userChannelRef = useRef<Channel | null>(null);

  useEffect(() => {
    if (!isScannerResultPage || !betId || userId === betUserId) {
      return;
    }

    const socket = new Socket(`${services.config.get(BET_RM_SOCKET_URL)}`, {
      params: { barcode },
      heartbeatIntervalMs: 10000,
      reconnectAfterMs: (tries: number): number => {
        return [1000, 2000, 5000, 10000][tries - 1] || 10000;
      },
    });

    socket.onOpen(() => {
      services.logger.log('Socket connected');
    });

    socket.onError(error => {
      services.logger.log('Socket error:', error);
      resetUpdates();
    });

    socket.onClose(() => {
      services.logger.log('Socket closed. Attempting to reconnect...');
    });

    socket.connect();

    const userChannel = socket.channel(`user:anonymous:bet:${betId}`);

    userChannel
      .join()
      .receive('ok', () => {
        services.logger.log('Joined channel successfully');
      })
      .receive('error', resp => {
        services.logger.log('Unable to join channel:', resp);
      });

    userChannel.on('offer', setBetsSocketData);

    socketRef.current = socket;
    userChannelRef.current = userChannel;
  }, [betId, barcode]);

  useEffect(
    () => () => {
      if (userChannelRef.current) {
        userChannelRef.current.leave();
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    },
    [],
  );
};
