import { IFieldConfig, IRegistrationData } from '@common/interfaces';

const config: Record<keyof IRegistrationData, IFieldConfig> = {
  no_bonus: {
    step: 0,
  },
  email: {
    step: 1,
  },
  email2: {
    step: 1,
  },
  login: {
    step: 1,
  },
  password: {
    step: 1,
  },
  password2: {
    step: 1,
  },
  bday_day: {
    step: 1,
  },
  bday_month: {
    step: 1,
  },
  bday_year: {
    step: 1,
  },
  country_code: {
    step: 1,
  },
  title: {
    step: 2,
  },
  sex: {
    step: 2,
  },
  firstname: {
    step: 2,
  },
  lastname: {
    step: 2,
  },
  maidenname: {
    step: 2,
  },
  country_of_birth: {
    step: 2,
  },
  place_of_birth: {
    step: 2,
  },
  nationality: {
    step: 2,
  },
  address_street_name: {
    step: 3,
  },
  address_street_number: {
    step: 3,
  },
  address_zip: {
    step: 3,
  },
  address_city: {
    step: 3,
  },
  region: {
    step: 3,
  },
  phone: {
    step: 3,
  },
  pep: {
    step: 4,
  },
  terms_agree: {
    step: 4,
  },
  privacy_policy_accepted: {
    step: 4,
  },
  newsletter_agree: {
    step: 4,
  },
  deposit_monthly_limit: {
    step: 4,
  },
  preferred_language: {
    step: 4,
  },
  no_sec_answer: {
    step: 4,
  },
  source_of_registration: {
    step: 4,
  },
};

export default config;
