import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MainAmount = styled.div`
  padding: ${({ theme }) => theme.space_10} 0;

  .partly-cashout-btn {
    margin-top: ${({ theme }) => theme.space_15};
  }

  .icon-close {
    margin: auto;
  }

  .input {
    width: 40%;
    margin-right: ${({ theme }) => theme.space_5};

    & > div {
      border: ${({ theme }) => theme.isDesktop && theme.border};
      border-radius: 3px;
    }
  }
`;
