import toString from 'lodash/toString';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import has from 'lodash/has';
import isString from 'lodash/isString';
import pick from 'lodash/pick';

import CustomError from '@features/core/error/error';
import i18n from '@features/core/translation';
import services from '@features/core/services';

import { HAS_JSONRPC_AUTH } from '@common/constants/config';
import { IUser, IUserInfo } from '@common/interfaces/user/IUser';
import fetchUserInfo from '@common/api/user/fetchUserInfo';
import fetchUserData from '@common/api/user/fetchUserData';
import fetchComplexUserAuth from '@common/api/user/fetchComplexUserAuth';
import { ICustomError } from '@common/interfaces/responses/IError';

/**
 * getFullUserInfo
 * combine user info and user customer data (street_name, nationality...) together for the full IUser
 *
 * @returns {IUser} IUser
 */
export default async (): Promise<IUser | ICustomError> => {
  const hasJSONRPCAuth = services.config.get(HAS_JSONRPC_AUTH);
  if (hasJSONRPCAuth) {
    const userInfo = await fetchUserInfo();
    const userData = await fetchUserData();

    if (userInfo instanceof CustomError || userData instanceof CustomError) {
      return new CustomError({
        code: userInfo?.name || userData?.name,
        message: i18n.t('common.labels.defaultError'),
      });
    }

    const userFullData: IUserInfo = { ...userInfo.result, ...userData.result };

    const reformatUserToIUserTypes = (user: IUserInfo): IUser => {
      const checkKeyExistance = (key: string): boolean => {
        return has(user, key);
      };

      const unchangedFields = pick(userFullData, [
        'country_of_birth',
        'created',
        'maidenname',
        'iban',
        'lineage',
        'place_of_birth',
        'wallet_types',
        'selfExclusionTag',
        'eventservice',
        'nationality',
        'street_name',
        'street_number',
        'zip_code',
        'city',
        'region',
        'email',
        'unverified_hrs_exceeded',
        'personal_information_review',
        'personal_information_status',
      ]);

      return {
        ...unchangedFields,
        liveCasinoEnabled: user.live_casino_enabled,
        vivoCasinoEnabled: user?.vivo_casino_enabled,
        acceptedTAC: toString(user.accepted_tac) as '1' | '0',
        hasOnlineAccount: checkKeyExistance('has_online_account')
          ? (toString(user.has_online_account) as '1' | '0')
          : undefined,
        universalCasinoEnabled: user?.universal_casino_enabled,
        casinoVerificationStatus: user?.casino_verification_status,
        deskoVerificationStatus: user?.desko_verification_status,
        sportsbookId: toString(user.sportsbook_id),
        countryCode: user.country_code,
        is_schleswig_holstein: checkKeyExistance('is_schleswig_holstein')
          ? (toString(user.is_schleswig_holstein) as '1' | '0')
          : undefined,
        verifiedIdentity: user?.verified_identity,
        minStake: user.min_stake,
        affiliateID: user?.affiliate_id,
        taxAsFee: user.show_tax_as_fee,
        taxLabel: user.tax_label,
        maxPayout: user.max_payout,
        isCasinoUser: user?.is_casino_user,
        isKioskUser: isString(user?.is_kiosk_user)
          ? parseInt(user.is_kiosk_user, 10)
          : undefined,
        avsVerificationStatus: user?.avs_verification_status,
        taxMethod: user.tax_method,
        casinoEnabled: user.casino_enabled,
        cashbackStatus: checkKeyExistance('cashback_status')
          ? (toString(user.cashback_status) as '1' | '0')
          : undefined,
        cashoutStatus: checkKeyExistance('cashout_status')
          ? (toString(user.cashout_status) as '1' | '0')
          : undefined,
        pariplayCasinoEnabled: user?.pariplay_casino_enabled,
        betsoftCasinoEnabled: user?.betsoft_casino_enabled,
        affiliateGroup: toString(user.affiliate_group),
        barbutEnabled: user.barbut_enabled,
        verifiedEmail: parseInt(user.verified_email_flags, 10),
        selfExclusionStatus: checkKeyExistance('self_exclusion_status')
          ? (toString(user.self_exclusion_status) as '0' | '1')
          : undefined,
        username: user.user_name,
        taxes: {
          1: parseFloat(user.tax_percentages.leg_size_1 || '0'),
          2: parseFloat(user.tax_percentages.leg_size_2 || '0'),
          3: parseFloat(user.tax_percentages.leg_size_3 || '0'),
          4: parseFloat(user.tax_percentages.leg_size_4 || '0'),
          5: parseFloat(user?.tax_percentages?.leg_size_5 || '0'),
          6: parseFloat(user?.tax_percentages?.leg_size_6 || '0'),
          7: parseFloat(user?.tax_percentages?.leg_size_7 || '0'),
          8: parseFloat(user?.tax_percentages?.leg_size_8 || '0'),
          9: parseFloat(user?.tax_percentages?.leg_size_9 || '0'),
          10: parseFloat(user?.tax_percentages?.leg_size_10 || '0'),
          live: parseFloat(user.tax_percentages.fully_live || '0'),
        },
        accountFlags: user.account_flags,
        isEmailVerified: user?.is_email_verified,
        type: user.user_type,
        first_deposit_done: checkKeyExistance('first_deposit_done')
          ? user?.first_deposit_done
          : undefined,
        currencyCode: user.currency_code,
        hasCasinoAccount: checkKeyExistance('has_casino_account')
          ? (toString(user.has_casino_account) as '1' | '0')
          : undefined,
      };
    };

    const reformattedUser = reformatUserToIUserTypes(userFullData);
    const filteredUser = omitBy(reformattedUser, isUndefined);
    filteredUser.taxes = omitBy(filteredUser.taxes, isUndefined);

    return filteredUser as IUser;
  }
  const userData = await fetchComplexUserAuth();
  return userData as IUser;
};
