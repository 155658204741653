import map from 'lodash/map';
import includes from 'lodash/includes';
import set from 'lodash/set';
import concat from 'lodash/concat';
import keys from 'lodash/keys';
import find from 'lodash/find';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import replace from 'lodash/replace';

import { PageName } from '@features/core/routing/linkAliases';
import i18next from '@features/core/translation';
import servicesInstance from '@features/core/services';

import {
  LAST_DEPOSIT_METHOD,
  LAST_WITHDRAWAL_METHOD,
} from '@common/constants/cookie';
import { HAS_LUGAS, HAS_SHOP_E_MONEY } from '@common/constants/config';
import numeral from '@common/helpers/numeralHelper';
import {
  IUser,
  IPaymentService,
  IFields,
  IOperation,
  IRequestPaymentError,
  IPaymentOperation,
  IPaymentMethod,
  IWallet,
} from '@common/interfaces';
import { IUserType } from '@common/interfaces/user/IUser';
import { getPaymentWallet } from '@common/helpers/paymentsHelper/walletsHelper';
import { usePaymentsState } from '@common/providers/payments/usePayments';
import {
  isShouldChangePassword,
  isShopUser,
} from '@common/helpers/userHelper/userHelper';
import {
  IPaymentBalanceOperation,
  IPaymentOperationResult,
} from '@common/interfaces/payments/IPayment';
import mustCreateOnlineAccount from '@common/helpers/userHelper/mustCreateOnlineAccount';

/**
 * needInitialVerification if user register using web/native platform
 * he has 72hrs to verify identity using manual verification
 * or insic widget (3rd party) doing 1 cent or document upload
 *
 * @param {IUser} user
 * @returns {boolean} needInitialVerification
 */
export const needInitialVerification = (user: IUser): boolean =>
  user?.personal_information_review === 0 &&
  user.avsVerificationStatus !== 'verified' &&
  !isShopUser() &&
  !!servicesInstance.config.get(HAS_LUGAS);
/**
 * needYealryVerification, once per year customer from Germany has to proceed data confirmation
 * If details like their adreess/phone were changed they could change it
 * all the other details could be changed by support team
 * Confirmation is done either manually or insic widget (3rd party)
 *
 * @param {IUser} user
 * @returns {boolean} needYealryVerification
 */
export const needYealryVerification = (user: IUser): boolean =>
  user?.personal_information_review === 1;

/**
 * needVerification
 *
 * @param {IUser} user
 * @returns {boolean} needVerification
 */
export const needVerification = (user: IUser): boolean =>
  needInitialVerification(user) || needYealryVerification(user);

/**
 * hasNoVerifiedEmail, checks if user has no verified email
 *
 * @param {IUser} user
 * @returns {boolean} hasNoVerifiedEmail
 */
export const hasNoVerifiedEmail = (user: IUser): boolean => {
  const isEmailVerified = user.isEmailVerified || 0;
  const verifiedEmailFlags = user.verifiedEmail;
  return (
    !isShouldChangePassword(user) &&
    !!user.email &&
    isEmailVerified === 0 &&
    verifiedEmailFlags > 1 &&
    verifiedEmailFlags < 28
  );
};

/**
 * is72HoursFrozen, checks if user has no verification after 72 hrs
 *
 * @param {IUser | null} user
 * @returns {boolean} is72HoursFrozen
 */
export const is72HoursFrozen = (user: IUser): boolean => {
  return (
    user?.personal_information_review === 0 &&
    !!user?.unverified_hrs_exceeded &&
    user.countryCode === 'DE' &&
    user.type === IUserType.www
  );
};

/**
 * isYearlyFrozen, checks if user has no verification after 1 year
 *
 * @param {IUser | null} user
 * @returns {boolean} isYearlyFrozen
 */
export const isYearlyFrozen = (user: IUser): boolean => {
  return (
    user?.personal_information_review === 1 &&
    !!user?.unverified_hrs_exceeded &&
    user.countryCode === 'DE' &&
    user.type === IUserType.www
  );
};

/**
 * hasFrozenAcccount, checks if user account is frozen
 *
 * @param {IUser | null} user
 * @returns {boolean} hasFrozenAcccount
 */
export const hasFrozenAcccount = (user: IUser): boolean => {
  return is72HoursFrozen(user) || isYearlyFrozen(user);
};

/**
 * getVerificationLink, returns correct verification link
 *
 * @param {IUser | null} user
 * @returns {string} getVerificationLink
 */
export const getVerificationLink = (user: IUser): PageName => {
  if (needInitialVerification(user)) {
    return PageName.USER_VERIFICATION;
  }
  if (
    needYealryVerification(user) &&
    user.personal_information_status === 'show_personal_information'
  ) {
    return PageName.YEARLY_DATA_CHECK;
  }
  return PageName.USER_VERIFICATION_YEARLY;
};

/**
 * isUserSelfExcluded, checks if user is self excluded
 *
 * @param {string | undefined} value
 * @returns {boolean} is user self excluded
 */
export const isUserSelfExcluded = (value): boolean => {
  // if '', '0', includes 'P0D' or 'active = user is not seltExluded
  return value ? !/^0|^P0D|^$|^active/.test(value) : !!value;
};

/**
 * canPerformOperation, checks if user can perform operation.
 * Online Users: verifiedIdentity is 0 for not verified online users
 * Shop Users: a verified shop user has either verifiedIdentity==1 or deskoVerificationStatus=='verified'
 * deskoVerificationStatus can be any of 'not_verified', 'pending' or 'verified'
 *
 * @param {IUser} user
 * @param {string} type
 * @returns {boolean} canPerformOperation
 */
export const canPerformOperation = (
  user: IUser,
  type:
    | IPaymentBalanceOperation
    | IPaymentOperation = IPaymentOperation.WITHDRAWAL,
): boolean => {
  if (
    (type === IPaymentBalanceOperation.BETTINGSLIP ||
      type === IPaymentOperation.DEPOSIT) &&
    hasFrozenAcccount(user)
  ) {
    return false;
  }

  if (
    type === IPaymentOperation.DEPOSIT &&
    isUserSelfExcluded(user?.selfExclusionTag)
  ) {
    return false;
  }

  if (type === IPaymentBalanceOperation.BETTINGSLIP && isShopUser()) {
    return true;
  }

  if (
    !isShopUser() &&
    (type === IPaymentBalanceOperation.BETTINGSLIP ||
      type === IPaymentOperation.DEPOSIT)
  ) {
    return !hasNoVerifiedEmail(user);
  }

  if (
    type === IPaymentOperation.WITHDRAWAL &&
    user.countryCode === 'DE' &&
    !isShopUser()
  ) {
    return (
      !hasNoVerifiedEmail(user) && user.avsVerificationStatus === 'verified'
    );
  }

  if (
    type === IPaymentOperation.WITHDRAWAL &&
    user.countryCode !== 'DE' &&
    !isShopUser()
  ) {
    return (
      !hasNoVerifiedEmail(user) &&
      (parseInt(user.verifiedIdentity || '0', 10) === 1 ||
        !servicesInstance.config.get(HAS_LUGAS))
    );
  }

  if (type === IPaymentBalanceOperation.CASHBACKS) {
    return (
      !hasNoVerifiedEmail(user) &&
      parseInt(user.cashbackStatus || '0', 10) === 1
    );
  }
  return false;
};

/**
 * getErrorByCode
 * convert error code to message
 *
 * @param {string} code
 * @param {string} message
 * @param {string} country
 * @param {IRequestPaymentError | null} error
 * @param {string} service
 * @returns {string} error
 */
export const getErrorByCode = (
  code: string | undefined,
  message: string,
  country = '',
  error: IRequestPaymentError | null,
  service = '',
): string => {
  let errorMessage = '';
  switch (String(code)) {
    case '79':
      errorMessage = i18next.t('payments.errors.unknownService');
      break;
    case '60':
      errorMessage = i18next.t('payments.errors.paypalCasino');
      break;
    case '55':
      errorMessage = i18next.t('payments.errors.account_exists');
      break;
    case '52':
      errorMessage = message || '';
      break;
    case '59':
      errorMessage = message || i18next.t('payments.errors.invalidField');
      break;
    case '47':
      errorMessage = i18next.t('payments.errors.Invalid_IBAN');
      break;
    case '50':
      errorMessage = i18next.t('payments.errors.insufficientFunds');
      break;
    case '70':
      break;
    case '71':
    case '72':
    case '73':
      errorMessage = i18next.t(`payments.errors.key_deposit_error_${code}`);
      break;
    case '99':
      errorMessage = i18next.t('payments.errors.service_error');
      break;
    case '53':
      errorMessage = i18next.t('payments.errors.serviceNotAvailable');
      break;
    case '68':
      errorMessage = i18next.t('payments.errors.closedLoopError');
      break;
    case '62':
      errorMessage = i18next.t('payments.errors.transactionRefused');
      break;
    case '54':
      errorMessage = replace(
        i18next.t('payments.errors.depositRequired'),
        /{method}/gi,
        service,
      );
      break;
    case '74':
      errorMessage = i18next.t('payments.errors.depositLugasExceeded', {
        depositAmount: /'([^']+)'/.exec(message)?.[1],
        availableLimit: error?.data?.available,
      });
      break;
    case '56':
      errorMessage =
        message ||
        replace(
          i18next.t('payments.errors.countryMismatch'),
          'origin',
          country,
        );
      break;
    default:
      errorMessage = message;
      break;
  }
  return String(code) && errorMessage;
};

export const getDisableReason = (user: IUser): IPaymentOperationResult => {
  if (mustCreateOnlineAccount(user)) {
    return IPaymentOperationResult.NO_ONLINE_ACCOUNT;
  }

  if (isShopUser()) {
    return IPaymentOperationResult.NOT_ALLOWED;
  }

  if (needVerification(user)) {
    return IPaymentOperationResult.NOT_VERIFIED;
  }

  return IPaymentOperationResult.NOT_CONFIRMED;
};

/**
 * sortPaymentServices
 * sorting order for deposits
 *
 * @param {IPaymentService[]} services
 * @returns {IPaymentService[]} sorted
 */
export const sortPaymentServices = (
  services: IPaymentService[],
): IPaymentService[] => {
  return sortBy(services, service =>
    [
      IPaymentMethod.SHOP_MONEY,
      IPaymentMethod.PAYPAL,
      IPaymentMethod.TRUSTLY,
      IPaymentMethod.CARD,
      IPaymentMethod.BANK,
      IPaymentMethod.SKRILL,
      IPaymentMethod.AIRCASH,
      IPaymentMethod.SOFORT,
      IPaymentMethod.PAYSAFECARD,
      IPaymentMethod.CARD_VISA,
      IPaymentMethod.MUCHBETTER,
    ].indexOf(service?.service?.id),
  );
};

/**
 * filterPaymentServices
 *
 * @param {IPaymentService[]} services
 * @param {IPaymentOperation} operation
 * @returns {IPaymentService[]} filterServicesExtended
 */
export const filterPaymentServices = (
  services: IPaymentService[],
  operation: IPaymentOperation,
): IPaymentService[] => {
  return filter(services, service => {
    return service.service[operation]?.status === 'enabled';
  });
};

const SERVICE_ID = 'service.id';

/**
 * getCardHolder
 * getCardHolder, gets card holder for bank transaction
 *
 * @param {Array<IPaymentService>} services
 * @param {IPaymentOperation} method
 * @returns {string | null} holder
 */
export const getCardHolder = (
  services: Array<IPaymentService>,
  method = IPaymentOperation.WITHDRAWAL,
): string | null => {
  const bankService = find(services, [SERVICE_ID, IPaymentMethod.BANK]);
  if (bankService) {
    const holderField = find(bankService.service[method].fields || [], [
      'name',
      'holder',
    ]);
    return holderField?.value || null;
  }
  return null;
};

/**
 * getCardIban
 * getCardIban, gets card iban for bank transaction
 *
 * @param {Array<IPaymentService>} services
 * @param {IPaymentOperation} method
 * @returns {IFields | null} iban
 */
export const getCardIban = (
  services: Array<IPaymentService>,
  method = IPaymentOperation.WITHDRAWAL,
): IFields | null => {
  const bankService = find(services, [SERVICE_ID, IPaymentMethod.BANK]);
  return (
    find(bankService?.service[method].fields || [], ['name', 'iban']) || null
  );
};

/**
 * getCardTypes
 * gets card types for card form
 *
 * @param {Array<IPaymentService>} services
 * @param {IPaymentOperation} method
 * @returns {Array<{ label: string; value: string }>} cardTypes
 */
export const getCardTypes = (
  services: Array<IPaymentService>,
  method = IPaymentOperation.DEPOSIT,
): Array<{ label: string; value: string }> => {
  const cardService = find(services, [SERVICE_ID, IPaymentMethod.CARD]);
  if (cardService) {
    const cardTypeField = find(
      (cardService.service[method] as IOperation).fields || [],
      field => {
        return field?.name === 'type';
      },
    );
    return cardTypeField?.options || [];
  }
  return [];
};

const manualLimits = {
  deposits: {},
  withdrawals: {},
};

/**
 *
 * @param {Array<IPaymentService>} services
 * @param {IPaymentMethod | null} selectedService
 * @returns {IPaymentService} service
 */
export const getPaymentService = (
  services: Array<IPaymentService>,
  selectedService: IPaymentMethod | null,
): IPaymentService => {
  return (
    find(services, ['service.id', selectedService]) || ({} as IPaymentService)
  );
};

export const getPaymentLimits = (
  operation: IPaymentOperation,
  selectedService: IPaymentMethod | null,
): IOperation['limit'] | null => {
  if (!selectedService) {
    return null;
  }
  const { services } = usePaymentsState.getState();
  const service = getPaymentService(services, selectedService);
  return (
    service?.service?.[operation]?.limit || manualLimits[selectedService]?.limit
  );
};

export const getMinLimit = (
  operation: IPaymentOperation,
  selectedService: IPaymentMethod | null,
): number => {
  const limit = getPaymentLimits(operation, selectedService);
  if (selectedService === IPaymentMethod.SHOP_MONEY) {
    return operation === IPaymentOperation.DEPOSIT ? 1 : 0.01;
  }
  return parseFloat(limit?.min || '0');
};

export const getMaxLimit = (
  operation: IPaymentOperation,
  selectedService: IPaymentMethod | null,
  selectedWallet?: IWallet,
): number => {
  const limit = getPaymentLimits(operation, selectedService);
  const paymentWallet = getPaymentWallet(operation, selectedWallet);
  if (selectedService === IPaymentMethod.SHOP_MONEY) {
    return parseFloat(paymentWallet?.balance as string) || 0;
  }
  return !!limit?.available && !!limit?.max && +limit?.available < +limit?.max
    ? parseFloat(limit?.available)
    : parseFloat(limit?.max as string);
};

export const getDefaultPaymentValue = (
  operation: IPaymentOperation,
  selectedService: IPaymentMethod | null,
): number => {
  if (selectedService === IPaymentMethod.SHOP_MONEY) {
    return getMaxLimit(operation, selectedService);
  }
  return getMinLimit(operation, selectedService);
};

export const getFormattedPaymentRestriction = (
  operation: IPaymentOperation,
  selectedService: IPaymentMethod,
  activeWallet?: IWallet,
): string => {
  const formalVal = (val: number, max?: boolean): string =>
    +val || !max ? numeral(+val).format('0.00') : '-';
  const limitMin = getMinLimit(operation, selectedService);
  const limitMax = getMaxLimit(operation, selectedService, activeWallet);
  return `Min.: ${formalVal(limitMin)} € | Max.: ${formalVal(
    limitMax,
    true,
  )} €`;
};

/**
 * setManualLimits
 *
 * @param {Array<IPaymentService>} payments
 * @returns {Array<IPaymentService>} services
 */
export const setManualLimits = (
  payments: Array<IPaymentService>,
): Array<IPaymentService> => {
  const depositsToUpdate = keys(manualLimits.deposits);
  const withdrawalsToUpdate = keys(manualLimits.withdrawals);
  return map(payments, e => {
    if (
      includes(concat(depositsToUpdate, withdrawalsToUpdate), e[SERVICE_ID])
    ) {
      let method = e;
      if (manualLimits.deposits[e.service.id]) {
        method = set(
          method,
          'service.deposit.limit.min',
          manualLimits.deposits[e.service.id].min ||
            method.service.deposit.limit.min ||
            null,
        );
        method = set(
          method,
          'service.deposit.limit.max',
          manualLimits.deposits[e.service.id].max ||
            method.service.deposit.limit.max ||
            null,
        );
      }
      if (manualLimits.withdrawals[e.service.id]) {
        method = set(
          method,
          'service.withdrawal.limit.min',
          manualLimits.withdrawals[e.service.id].min ||
            method.service.withdrawal.limit.min ||
            null,
        );
        method = set(
          method,
          'service.withdrawal.limit.max',

          manualLimits.withdrawals[e.service.id].max ||
            method.service.withdrawal.limit.max ||
            null,
        );
      }
      return method;
    }
    return e;
  });
};

export const getDefaultPaymentMethod = (
  operation: IPaymentOperation,
): IPaymentMethod | null => {
  if (
    !servicesInstance.config.get(HAS_LUGAS) &&
    operation === IPaymentOperation.WITHDRAWAL
  ) {
    return IPaymentMethod.AIRCASH;
  }
  const lastChosenService = servicesInstance.cookie.get(
    operation === IPaymentOperation.DEPOSIT
      ? LAST_DEPOSIT_METHOD
      : LAST_WITHDRAWAL_METHOD,
  ) as IPaymentMethod;
  const shopBalance = getDefaultPaymentValue(
    operation,
    IPaymentMethod.SHOP_MONEY,
  );
  const shopMinAmount = getMinLimit(operation, IPaymentMethod.SHOP_MONEY);

  if (lastChosenService && lastChosenService !== IPaymentMethod.SHOP_MONEY) {
    return lastChosenService;
  }
  if (
    shopBalance > shopMinAmount &&
    !!servicesInstance.config.get(HAS_SHOP_E_MONEY)
  ) {
    return IPaymentMethod.SHOP_MONEY;
  }
  return null;
};
