import React from 'react';

import services from '@features/core/services';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import { ICashoutPinIcon } from '@features/cashout/components/cashoutPinIcon/CashoutPinIcon.types';

import {
  addCashoutFavorites,
  removeCashoutFavorites,
} from '@common/providers/cashout/useCashout';
import getImageBasePath from '@common/helpers/getImageBasePath';
import { HAS_CATEGORIES_FAVORITES } from '@common/constants/config';

import * as S from './CashoutPinIcon.styled';

const CashoutPinIcon: React.FC<ICashoutPinIcon> = ({ betId }) => {
  const { isFavorite } = useCashoutItemModel(betId);

  const iconSrc = isFavorite
    ? '/images/cashout-pin-filled-icon.svg'
    : '/images/cashout-pin-non-filled-icon.svg';

  const iconAlt = isFavorite ? 'cashout-favorite' : 'cashout-non-favorite';

  if (!services.config.get(HAS_CATEGORIES_FAVORITES)) {
    return null;
  }

  return (
    <S.ImageWrapper>
      <S.ImageStyled
        onClick={
          (): void =>
            isFavorite
              ? removeCashoutFavorites(betId)
              : addCashoutFavorites(betId)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        src={getImageBasePath(iconSrc)}
        alt={iconAlt}
      />
    </S.ImageWrapper>
  );
};

export default CashoutPinIcon;
